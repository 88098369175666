// colors
$white: #fff;
$black: #000;
$grey: #2A2F35;
$green: #359C9D;
$yellow: #FFA404;
$red: #FF3200;

//header colors
// font

$Lato-Regular: "Lato-Regular", sans-serif;
$Lato-Bold: "Lato-Bold", sans-serif;
$Lato-Light: "Lato-Light", sans-serif;

$Proxima-Regular: "Proxima-Regular", sans-serif;
$Proxima-Bold: "Proxima-Bold", sans-serif;

$Panton-LightCaps: "Panton-LightCaps", sans-serif;
$Panton-Light: "Panton-Light", sans-serif;
$Panton-SemiBold: "Panton-SemiBold", sans-serif;
$Panton-Italic: "Panton-Italic", sans-serif;
$Panton-LightItalic: "Panton-LightItalic", sans-serif;
$Panton-Bold: "Panton-Bold", sans-serif;

$Roboto-Bold: "Roboto-Bold", sans-serif;
