.message-box {
  display: flex;
  flex-direction: column;
  font-size: 2.1rem;
  line-height: 2.8rem;
  h2 {
    font-family: $Panton-Bold;
  }
  div {
    margin: 5px 0;
    width: 100%;
    height: 1px;
    background-color: $grey;
  }
  a {
    color: $grey;
  }
}
.main-map {
  width: 100%;
  display: flex;
  height: 550px;
  margin-top: 40px;
  margin-bottom: 40px;
  &__google {
    width: 60%;
  }
  &__image {
    width: 40%;
    background-image: url(../images/main-map.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

@media screen and (max-width: 1500px) {
  .main-map {
    height: 400px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .message-box {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
}
@media screen and (max-width: 1024px) {
  .main-map {
    height: 400px;
    margin-top: 25px;
    margin-bottom: 25px;
    &__google {
      width: 100%;
    }
    &__image {
      display: none;
    }
  }
  .message-box {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
}

@media screen and (max-width: 500px) {
  .main-map {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
