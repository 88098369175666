.header {
  width: 100%;
  //background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  transition: all 0.3s;
  top: 0px;
  z-index: 5;
  &__top {
    display: flex;
    align-items: center;
    height: 45px;
    color: $white;
    font-size: 1.1rem;
    font-family: $Roboto-Bold;
    border-bottom: 1px solid $white;
    padding-left: 6%;
    a {
      color: $white;
    }
    &--telephone, &--contact, &--clock  {
      height: 100%;
      line-height: 45px;
      padding: 0 25px;
      background-repeat: no-repeat;
      background-position: left center;
    }
    &--telephone {
      background-image: url(../images/menu-tel.svg);
      padding-left: 21px;
      border-right: 1px solid $white;
    }
    &--contact {
      background-image: url(../images/menu-contact.svg);
      padding-left: 60px;
      border-right: 1px solid $white;
      background-position: left 25px center;
    }
    &--clock {
      background-image: url(../images/menu-clock.svg);
      padding-left: 54px;
      background-position: left 25px center;
    }
  }
}
#offert {
  cursor: pointer;
}
.header.active {
  top: -45px;
  background-color: $grey;
  transition: all 0.3s;
  .nav__list {
    height: 65px;
  }
  .nav__logo img {
    height: 50px;
    transition: all 0.3s;
  }
}
.header:hover {
  background-color: $grey;
  transition: all 0.3s;

}
.nav {
  width: 100%;
  display: flex;
  &__logo {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    font-size: 2.5rem;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      img {
       height: 70px;
       margin: 5px 0;
       transition: all 0.3s;
      }
    }
  }
  &__list {
    height: 80px;
    width: 85%;
    display: flex;
    align-items: center;
    > ul {
      display: flex;
      align-items: center;
      padding: 10px 0;
      width: 100%;
      padding-right: 5%;
      justify-content: space-between;
      > li {
        margin: 0 1px;
        border: 1px solid transparent;
        transition: all 0.3s;
        > a {
          display: block;
          padding: 10px 25px;
          color: $white;
          font-family: $Lato-Bold;
          font-size: 2.3rem;
          text-transform: uppercase;
        }
      }
      li.active-card {
        border: 1px solid $white;
      }
      > li.current-active {
        border: 1px solid $white;
      }
      li:hover {
        //border: 1px solid $white;
        //transition: all 0.3s;
        background-color: darken($grey, 2);
      }
      li:hover > .dropdown   {
        display: block;
      }
      li:hover > .dropdown ul li  {
        opacity: 0;
        @for $i from 1 through 7 {
          &:nth-child(#{$i}) {
            animation-name: dropdown;
            animation-duration: 50ms * $i;
            animation-delay: (50ms * $i - 150);
            animation-timing-function: ease-in-out;
            animation-fill-mode: forwards;
          }
        }
      }
    }
  }
}

@keyframes dropdown {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  100% {
    opacity: 100%;
    transform: rotateX(0deg);
    opacity: 1;
  }
}
.dropdown {
  display: none;
  color: $black;
  position: absolute;
  transform: translate(-40%, 0);
  ul {
    display: flex;
    margin-top: 10px;
    padding: 30px;
    padding-top: 0px;
    flex-wrap: wrap;
    width: 1200px;
    li {
      width: 33.333333%;
      border: 1px solid transparent;
      border-bottom: 1px solid #1b1b1b;
      border-right: 1px solid #1b1b1b;
      background-color: $grey;
      display: flex;
      align-items: center;
      padding: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      a {
        display: block;
        padding: 15px 0;
        padding-left: 0px;
        padding-right: 0px;
        font-family: $Panton-LightCaps;
        font-size: 18px;
        color: #fff;
        text-align: center;
        transition: color 0.3s;
      }
      div {
        width: 100px;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
      }
      img {
        display: block;
        height: auto;
        width: 100%;
      }
    }
    li:first-child {
    }
    li:last-child {
    }
    li:last-child:hover {
    }
    li:hover  {
      //border: none;
      //border-bottom: 1px solid #B2B4B6;
      a {
        color: $yellow;
        transition: color 0.3s;
      }
    }
  }
}



// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

.hamburger-container {
  display: none;
}
.menu-button {
  //border: 4px solid #000;
  //width: 60px;
  //height: 58px;
  //border-radius: 50px;
}
.hamburger {
  padding-left: 30px;
  height: 100%;
  margin-top: -5px;
}
.menu-button-bar1, .menu-button-bar2, .menu-button-bar3 {
  position: relative;
  top: 20px;
  left: 5px;
  border-radius: 0px;
  display: block;
  background-color: $black;
  width: 30px;
  height: 3px;
  margin: 5px;
  transition: all 0.25s;
}
.animate1 {
  transition: 0.25s;
  transform: translate(0px, 8px) rotate(45deg);
}

.animate2 {
  transition: 0.25s;
  opacity: 0;
}

.animate3 {
  transition: 0.25s;
  transform: translate(0px, -8px) rotate(-45deg);
}

@media screen and (max-width: 1700px) {
  .nav__list > ul > li > a {
    padding: 7px 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 1500px) {
  .nav__logo a img {
    height: 50px;
  }
  .header.active {
    top: -30px;
  }
  .dropdown ul li a {
    font-size: 1.2rem;
    padding: 10px 0;
    padding-left: 15px;
    padding-right: 15px;
  }
  .header__top--telephone, .header__top--contact, .header__top--clock {
    padding: 0 15px;
    line-height: 32px;
  }
  .nav__logo {
    font-size: 1.7rem;
  }
  .nav__list > ul > li > a {
    padding: 5px 15px;
    font-size: 1.6rem;
  }
  .header__top {
    font-size: 0.8rem;
    height: 30px;
  }
  .header__top--telephone {
    background-size: 12px;
    padding-left: 18px;
  }
  .header__top--contact {
    background-size: 22px;
    background-position: left 15px center;
    padding-left: 43px;
  }
  .header__top--clock {
    background-size: 17px;
    background-position: left 15px center;
    padding-left: 39px;
  }
  .header.active {
    .nav__list {
      height: 55px;
    }
    .nav__logo img {
      height: 40px;
      transition: all 0.3s;
    }
  }
  .nav__list {
    height: 60px;
  }
  .dropdown ul {
    width: 900px;
  }
  .dropdown ul li a {
    padding-left: 10px;
    padding-right: 10px;
  }
  .dropdown ul li div {
    width: 80px;
    margin-right: 15px;
  }
  .dropdown ul li {
    border: 1px solid transparent;
    border-bottom: 1px solid #1b1b1b;
    border-right: 1px solid #1b1b1b;
  }
  .dropdown {
    transform: translate(-45%,0);
  }
}

@media screen and (max-width: 1150px) {

  .nav__list > ul > li > a {
    font-size: 1.4rem;
    padding: 4px 12px;
  }
}

@media screen and (max-width: 1024px) {
  .nav__list > ul li:hover {
    background-color: $grey;
  }
  .dropdown ul {
    width: auto;
  }
  .dropdown ul li {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: center;
  }
  .dropdown ul li {
    padding: 5px 0;
  }
  .dropdown ul li a {
    text-align: center;
  }
  .dropdown ul li div {
    display: none;
  }
  .header.active {
    .nav__list {
      height: 55px;
    }
    .nav__logo img {
      height: 50px;
      transition: all 0.3s;
    }
  }
  .nav {
    height: 60px;
    position: relative;
    background-color: $grey;
    flex-wrap: wrap;
    max-height: 100vh;
  }
  .hamburger-container {
    display: block;
    order: 1;
    width: 20%;
    height: 100%;
  }
  .hamburger {
    padding-left: 20px;
  }
  .header__top {
    display: none;
  }
  .nav__logo {
    order: 2;
    width: 60%;
    height: 100%;
  }
  .nav__list  {
    display: none;
    order: 3;
  }
  .header.active {
    top: 0;
  }
  .header.header-active   .nav__list  {
    overflow-y: scroll;
    display: block;
    background-color: $grey;
    width: 100%;
    height: auto;
    //padding: 0 20px;
    //display: flex;
    //justify-content: center;
    -webkit-overflow-scrolling:touch; // mobile safari
    > ul {
      //margin-top: 60px;
      //height: 100%;
      margin: 0 auto;
      overflow-y: scroll;
      display: block;
      padding: 0;
      text-align: center;
      width: auto;
      padding: 20px 0;
      padding-bottom: 60px;
      display: flex;
      flex-direction: column;
      > li {
        padding: 5px 25px;
        display: inline-block;
      }
      li:hover > .dropdown   {
        display: none;
      }
    }
  }
  .dropdown {
    color: $black;
    position: static;
    transform: translate(0, 0);
    ul {
      display: block;
      margin-top: 10px;
      padding: 30px;
      padding-top: 0px;
      li {
        border: none;
        border-bottom: 1px solid #B2B4B6;
        background-color: $grey;
        a {
          display: block;
          padding: 15px 0;
          padding-left: 20px;
          padding-right: 20px;
          font-family: $Panton-LightCaps;
          font-size: 18px;
          color: #B2B4B6;
        }
      }
      li:first-child {
        padding-top: 5px;
      }
      li:last-child {
        border: none;
        padding-bottom: 5px;
      }
      li:last-child:hover {
        border: none;
      }
      li:hover  {
        border: none;
        border-bottom: 1px solid #B2B4B6;
        a {
          color: $white;
        }
      }
    }
  }
}


@media screen and (max-width: 500px) {
  .dropdown ul li a {
    font-size: 15px;
    padding: 14px 0;
  }
  .dropdown ul {
    padding: 10px;
  }
  .header.header-active .nav__list > ul > li:last-child {
    padding-bottom: 10px;
  }
}
