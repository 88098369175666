/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .navbar {
    display: none;
  }
  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }
  .label {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("../fonts/bootstrap/glyphicons-halflings-regular.eot");
  src: url("../fonts/bootstrap/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/bootstrap/glyphicons-halflings-regular.woff2") format("woff2"), url("../fonts/bootstrap/glyphicons-halflings-regular.woff") format("woff"), url("../fonts/bootstrap/glyphicons-halflings-regular.ttf") format("truetype"), url("../fonts/bootstrap/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg");
}

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-asterisk:before {
  content: "\002a";
}

.glyphicon-plus:before {
  content: "\002b";
}

.glyphicon-euro:before,
.glyphicon-eur:before {
  content: "\20ac";
}

.glyphicon-minus:before {
  content: "\2212";
}

.glyphicon-cloud:before {
  content: "\2601";
}

.glyphicon-envelope:before {
  content: "\2709";
}

.glyphicon-pencil:before {
  content: "\270f";
}

.glyphicon-glass:before {
  content: "\e001";
}

.glyphicon-music:before {
  content: "\e002";
}

.glyphicon-search:before {
  content: "\e003";
}

.glyphicon-heart:before {
  content: "\e005";
}

.glyphicon-star:before {
  content: "\e006";
}

.glyphicon-star-empty:before {
  content: "\e007";
}

.glyphicon-user:before {
  content: "\e008";
}

.glyphicon-film:before {
  content: "\e009";
}

.glyphicon-th-large:before {
  content: "\e010";
}

.glyphicon-th:before {
  content: "\e011";
}

.glyphicon-th-list:before {
  content: "\e012";
}

.glyphicon-ok:before {
  content: "\e013";
}

.glyphicon-remove:before {
  content: "\e014";
}

.glyphicon-zoom-in:before {
  content: "\e015";
}

.glyphicon-zoom-out:before {
  content: "\e016";
}

.glyphicon-off:before {
  content: "\e017";
}

.glyphicon-signal:before {
  content: "\e018";
}

.glyphicon-cog:before {
  content: "\e019";
}

.glyphicon-trash:before {
  content: "\e020";
}

.glyphicon-home:before {
  content: "\e021";
}

.glyphicon-file:before {
  content: "\e022";
}

.glyphicon-time:before {
  content: "\e023";
}

.glyphicon-road:before {
  content: "\e024";
}

.glyphicon-download-alt:before {
  content: "\e025";
}

.glyphicon-download:before {
  content: "\e026";
}

.glyphicon-upload:before {
  content: "\e027";
}

.glyphicon-inbox:before {
  content: "\e028";
}

.glyphicon-play-circle:before {
  content: "\e029";
}

.glyphicon-repeat:before {
  content: "\e030";
}

.glyphicon-refresh:before {
  content: "\e031";
}

.glyphicon-list-alt:before {
  content: "\e032";
}

.glyphicon-lock:before {
  content: "\e033";
}

.glyphicon-flag:before {
  content: "\e034";
}

.glyphicon-headphones:before {
  content: "\e035";
}

.glyphicon-volume-off:before {
  content: "\e036";
}

.glyphicon-volume-down:before {
  content: "\e037";
}

.glyphicon-volume-up:before {
  content: "\e038";
}

.glyphicon-qrcode:before {
  content: "\e039";
}

.glyphicon-barcode:before {
  content: "\e040";
}

.glyphicon-tag:before {
  content: "\e041";
}

.glyphicon-tags:before {
  content: "\e042";
}

.glyphicon-book:before {
  content: "\e043";
}

.glyphicon-bookmark:before {
  content: "\e044";
}

.glyphicon-print:before {
  content: "\e045";
}

.glyphicon-camera:before {
  content: "\e046";
}

.glyphicon-font:before {
  content: "\e047";
}

.glyphicon-bold:before {
  content: "\e048";
}

.glyphicon-italic:before {
  content: "\e049";
}

.glyphicon-text-height:before {
  content: "\e050";
}

.glyphicon-text-width:before {
  content: "\e051";
}

.glyphicon-align-left:before {
  content: "\e052";
}

.glyphicon-align-center:before {
  content: "\e053";
}

.glyphicon-align-right:before {
  content: "\e054";
}

.glyphicon-align-justify:before {
  content: "\e055";
}

.glyphicon-list:before {
  content: "\e056";
}

.glyphicon-indent-left:before {
  content: "\e057";
}

.glyphicon-indent-right:before {
  content: "\e058";
}

.glyphicon-facetime-video:before {
  content: "\e059";
}

.glyphicon-picture:before {
  content: "\e060";
}

.glyphicon-map-marker:before {
  content: "\e062";
}

.glyphicon-adjust:before {
  content: "\e063";
}

.glyphicon-tint:before {
  content: "\e064";
}

.glyphicon-edit:before {
  content: "\e065";
}

.glyphicon-share:before {
  content: "\e066";
}

.glyphicon-check:before {
  content: "\e067";
}

.glyphicon-move:before {
  content: "\e068";
}

.glyphicon-step-backward:before {
  content: "\e069";
}

.glyphicon-fast-backward:before {
  content: "\e070";
}

.glyphicon-backward:before {
  content: "\e071";
}

.glyphicon-play:before {
  content: "\e072";
}

.glyphicon-pause:before {
  content: "\e073";
}

.glyphicon-stop:before {
  content: "\e074";
}

.glyphicon-forward:before {
  content: "\e075";
}

.glyphicon-fast-forward:before {
  content: "\e076";
}

.glyphicon-step-forward:before {
  content: "\e077";
}

.glyphicon-eject:before {
  content: "\e078";
}

.glyphicon-chevron-left:before {
  content: "\e079";
}

.glyphicon-chevron-right:before {
  content: "\e080";
}

.glyphicon-plus-sign:before {
  content: "\e081";
}

.glyphicon-minus-sign:before {
  content: "\e082";
}

.glyphicon-remove-sign:before {
  content: "\e083";
}

.glyphicon-ok-sign:before {
  content: "\e084";
}

.glyphicon-question-sign:before {
  content: "\e085";
}

.glyphicon-info-sign:before {
  content: "\e086";
}

.glyphicon-screenshot:before {
  content: "\e087";
}

.glyphicon-remove-circle:before {
  content: "\e088";
}

.glyphicon-ok-circle:before {
  content: "\e089";
}

.glyphicon-ban-circle:before {
  content: "\e090";
}

.glyphicon-arrow-left:before {
  content: "\e091";
}

.glyphicon-arrow-right:before {
  content: "\e092";
}

.glyphicon-arrow-up:before {
  content: "\e093";
}

.glyphicon-arrow-down:before {
  content: "\e094";
}

.glyphicon-share-alt:before {
  content: "\e095";
}

.glyphicon-resize-full:before {
  content: "\e096";
}

.glyphicon-resize-small:before {
  content: "\e097";
}

.glyphicon-exclamation-sign:before {
  content: "\e101";
}

.glyphicon-gift:before {
  content: "\e102";
}

.glyphicon-leaf:before {
  content: "\e103";
}

.glyphicon-fire:before {
  content: "\e104";
}

.glyphicon-eye-open:before {
  content: "\e105";
}

.glyphicon-eye-close:before {
  content: "\e106";
}

.glyphicon-warning-sign:before {
  content: "\e107";
}

.glyphicon-plane:before {
  content: "\e108";
}

.glyphicon-calendar:before {
  content: "\e109";
}

.glyphicon-random:before {
  content: "\e110";
}

.glyphicon-comment:before {
  content: "\e111";
}

.glyphicon-magnet:before {
  content: "\e112";
}

.glyphicon-chevron-up:before {
  content: "\e113";
}

.glyphicon-chevron-down:before {
  content: "\e114";
}

.glyphicon-retweet:before {
  content: "\e115";
}

.glyphicon-shopping-cart:before {
  content: "\e116";
}

.glyphicon-folder-close:before {
  content: "\e117";
}

.glyphicon-folder-open:before {
  content: "\e118";
}

.glyphicon-resize-vertical:before {
  content: "\e119";
}

.glyphicon-resize-horizontal:before {
  content: "\e120";
}

.glyphicon-hdd:before {
  content: "\e121";
}

.glyphicon-bullhorn:before {
  content: "\e122";
}

.glyphicon-bell:before {
  content: "\e123";
}

.glyphicon-certificate:before {
  content: "\e124";
}

.glyphicon-thumbs-up:before {
  content: "\e125";
}

.glyphicon-thumbs-down:before {
  content: "\e126";
}

.glyphicon-hand-right:before {
  content: "\e127";
}

.glyphicon-hand-left:before {
  content: "\e128";
}

.glyphicon-hand-up:before {
  content: "\e129";
}

.glyphicon-hand-down:before {
  content: "\e130";
}

.glyphicon-circle-arrow-right:before {
  content: "\e131";
}

.glyphicon-circle-arrow-left:before {
  content: "\e132";
}

.glyphicon-circle-arrow-up:before {
  content: "\e133";
}

.glyphicon-circle-arrow-down:before {
  content: "\e134";
}

.glyphicon-globe:before {
  content: "\e135";
}

.glyphicon-wrench:before {
  content: "\e136";
}

.glyphicon-tasks:before {
  content: "\e137";
}

.glyphicon-filter:before {
  content: "\e138";
}

.glyphicon-briefcase:before {
  content: "\e139";
}

.glyphicon-fullscreen:before {
  content: "\e140";
}

.glyphicon-dashboard:before {
  content: "\e141";
}

.glyphicon-paperclip:before {
  content: "\e142";
}

.glyphicon-heart-empty:before {
  content: "\e143";
}

.glyphicon-link:before {
  content: "\e144";
}

.glyphicon-phone:before {
  content: "\e145";
}

.glyphicon-pushpin:before {
  content: "\e146";
}

.glyphicon-usd:before {
  content: "\e148";
}

.glyphicon-gbp:before {
  content: "\e149";
}

.glyphicon-sort:before {
  content: "\e150";
}

.glyphicon-sort-by-alphabet:before {
  content: "\e151";
}

.glyphicon-sort-by-alphabet-alt:before {
  content: "\e152";
}

.glyphicon-sort-by-order:before {
  content: "\e153";
}

.glyphicon-sort-by-order-alt:before {
  content: "\e154";
}

.glyphicon-sort-by-attributes:before {
  content: "\e155";
}

.glyphicon-sort-by-attributes-alt:before {
  content: "\e156";
}

.glyphicon-unchecked:before {
  content: "\e157";
}

.glyphicon-expand:before {
  content: "\e158";
}

.glyphicon-collapse-down:before {
  content: "\e159";
}

.glyphicon-collapse-up:before {
  content: "\e160";
}

.glyphicon-log-in:before {
  content: "\e161";
}

.glyphicon-flash:before {
  content: "\e162";
}

.glyphicon-log-out:before {
  content: "\e163";
}

.glyphicon-new-window:before {
  content: "\e164";
}

.glyphicon-record:before {
  content: "\e165";
}

.glyphicon-save:before {
  content: "\e166";
}

.glyphicon-open:before {
  content: "\e167";
}

.glyphicon-saved:before {
  content: "\e168";
}

.glyphicon-import:before {
  content: "\e169";
}

.glyphicon-export:before {
  content: "\e170";
}

.glyphicon-send:before {
  content: "\e171";
}

.glyphicon-floppy-disk:before {
  content: "\e172";
}

.glyphicon-floppy-saved:before {
  content: "\e173";
}

.glyphicon-floppy-remove:before {
  content: "\e174";
}

.glyphicon-floppy-save:before {
  content: "\e175";
}

.glyphicon-floppy-open:before {
  content: "\e176";
}

.glyphicon-credit-card:before {
  content: "\e177";
}

.glyphicon-transfer:before {
  content: "\e178";
}

.glyphicon-cutlery:before {
  content: "\e179";
}

.glyphicon-header:before {
  content: "\e180";
}

.glyphicon-compressed:before {
  content: "\e181";
}

.glyphicon-earphone:before {
  content: "\e182";
}

.glyphicon-phone-alt:before {
  content: "\e183";
}

.glyphicon-tower:before {
  content: "\e184";
}

.glyphicon-stats:before {
  content: "\e185";
}

.glyphicon-sd-video:before {
  content: "\e186";
}

.glyphicon-hd-video:before {
  content: "\e187";
}

.glyphicon-subtitles:before {
  content: "\e188";
}

.glyphicon-sound-stereo:before {
  content: "\e189";
}

.glyphicon-sound-dolby:before {
  content: "\e190";
}

.glyphicon-sound-5-1:before {
  content: "\e191";
}

.glyphicon-sound-6-1:before {
  content: "\e192";
}

.glyphicon-sound-7-1:before {
  content: "\e193";
}

.glyphicon-copyright-mark:before {
  content: "\e194";
}

.glyphicon-registration-mark:before {
  content: "\e195";
}

.glyphicon-cloud-download:before {
  content: "\e197";
}

.glyphicon-cloud-upload:before {
  content: "\e198";
}

.glyphicon-tree-conifer:before {
  content: "\e199";
}

.glyphicon-tree-deciduous:before {
  content: "\e200";
}

.glyphicon-cd:before {
  content: "\e201";
}

.glyphicon-save-file:before {
  content: "\e202";
}

.glyphicon-open-file:before {
  content: "\e203";
}

.glyphicon-level-up:before {
  content: "\e204";
}

.glyphicon-copy:before {
  content: "\e205";
}

.glyphicon-paste:before {
  content: "\e206";
}

.glyphicon-alert:before {
  content: "\e209";
}

.glyphicon-equalizer:before {
  content: "\e210";
}

.glyphicon-king:before {
  content: "\e211";
}

.glyphicon-queen:before {
  content: "\e212";
}

.glyphicon-pawn:before {
  content: "\e213";
}

.glyphicon-bishop:before {
  content: "\e214";
}

.glyphicon-knight:before {
  content: "\e215";
}

.glyphicon-baby-formula:before {
  content: "\e216";
}

.glyphicon-tent:before {
  content: "\26fa";
}

.glyphicon-blackboard:before {
  content: "\e218";
}

.glyphicon-bed:before {
  content: "\e219";
}

.glyphicon-apple:before {
  content: "\f8ff";
}

.glyphicon-erase:before {
  content: "\e221";
}

.glyphicon-hourglass:before {
  content: "\231b";
}

.glyphicon-lamp:before {
  content: "\e223";
}

.glyphicon-duplicate:before {
  content: "\e224";
}

.glyphicon-piggy-bank:before {
  content: "\e225";
}

.glyphicon-scissors:before {
  content: "\e226";
}

.glyphicon-bitcoin:before {
  content: "\e227";
}

.glyphicon-btc:before {
  content: "\e227";
}

.glyphicon-xbt:before {
  content: "\e227";
}

.glyphicon-yen:before {
  content: "\00a5";
}

.glyphicon-jpy:before {
  content: "\00a5";
}

.glyphicon-ruble:before {
  content: "\20bd";
}

.glyphicon-rub:before {
  content: "\20bd";
}

.glyphicon-scale:before {
  content: "\e230";
}

.glyphicon-ice-lolly:before {
  content: "\e231";
}

.glyphicon-ice-lolly-tasted:before {
  content: "\e232";
}

.glyphicon-education:before {
  content: "\e233";
}

.glyphicon-option-horizontal:before {
  content: "\e234";
}

.glyphicon-option-vertical:before {
  content: "\e235";
}

.glyphicon-menu-hamburger:before {
  content: "\e236";
}

.glyphicon-modal-window:before {
  content: "\e237";
}

.glyphicon-oil:before {
  content: "\e238";
}

.glyphicon-grain:before {
  content: "\e239";
}

.glyphicon-sunglasses:before {
  content: "\e240";
}

.glyphicon-text-size:before {
  content: "\e241";
}

.glyphicon-text-color:before {
  content: "\e242";
}

.glyphicon-text-background:before {
  content: "\e243";
}

.glyphicon-object-align-top:before {
  content: "\e244";
}

.glyphicon-object-align-bottom:before {
  content: "\e245";
}

.glyphicon-object-align-horizontal:before {
  content: "\e246";
}

.glyphicon-object-align-left:before {
  content: "\e247";
}

.glyphicon-object-align-vertical:before {
  content: "\e248";
}

.glyphicon-object-align-right:before {
  content: "\e249";
}

.glyphicon-triangle-right:before {
  content: "\e250";
}

.glyphicon-triangle-left:before {
  content: "\e251";
}

.glyphicon-triangle-bottom:before {
  content: "\e252";
}

.glyphicon-triangle-top:before {
  content: "\e253";
}

.glyphicon-console:before {
  content: "\e254";
}

.glyphicon-superscript:before {
  content: "\e255";
}

.glyphicon-subscript:before {
  content: "\e256";
}

.glyphicon-menu-left:before {
  content: "\e257";
}

.glyphicon-menu-right:before {
  content: "\e258";
}

.glyphicon-menu-down:before {
  content: "\e259";
}

.glyphicon-menu-up:before {
  content: "\e260";
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h1 small,
h1 .small, h2 small,
h2 .small, h3 small,
h3 .small, h4 small,
h4 .small, h5 small,
h5 .small, h6 small,
h6 .small,
.h1 small,
.h1 .small, .h2 small,
.h2 .small, .h3 small,
.h3 .small, .h4 small,
.h4 .small, .h5 small,
.h5 .small, .h6 small,
.h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #777777;
}

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h1 small,
h1 .small, .h1 small,
.h1 .small,
h2 small,
h2 .small, .h2 small,
.h2 .small,
h3 small,
h3 .small, .h3 small,
.h3 .small {
  font-size: 65%;
}

h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h4 small,
h4 .small, .h4 small,
.h4 .small,
h5 small,
h5 .small, .h5 small,
.h5 .small,
h6 small,
h6 .small, .h6 small,
.h6 .small {
  font-size: 75%;
}

h1, .h1 {
  font-size: 36px;
}

h2, .h2 {
  font-size: 30px;
}

h3, .h3 {
  font-size: 24px;
}

h4, .h4 {
  font-size: 18px;
}

h5, .h5 {
  font-size: 14px;
}

h6, .h6 {
  font-size: 12px;
}

p {
  margin: 0 0 10px;
}

.lead {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
}

@media (min-width: 0px) {
  .lead {
    font-size: 21px;
  }
}

small,
.small {
  font-size: 85%;
}

mark,
.mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase, .initialism {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-muted {
  color: #777777;
}

.text-primary {
  color: #337ab7;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #286090;
}

.text-success {
  color: #3c763d;
}

a.text-success:hover,
a.text-success:focus {
  color: #2b542c;
}

.text-info {
  color: #31708f;
}

a.text-info:hover,
a.text-info:focus {
  color: #245269;
}

.text-warning {
  color: #8a6d3b;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #66512c;
}

.text-danger {
  color: #a94442;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #843534;
}

.bg-primary {
  color: #fff;
}

.bg-primary {
  background-color: #337ab7;
}

a.bg-primary:hover,
a.bg-primary:focus {
  background-color: #286090;
}

.bg-success {
  background-color: #dff0d8;
}

a.bg-success:hover,
a.bg-success:focus {
  background-color: #c1e2b3;
}

.bg-info {
  background-color: #d9edf7;
}

a.bg-info:hover,
a.bg-info:focus {
  background-color: #afd9ee;
}

.bg-warning {
  background-color: #fcf8e3;
}

a.bg-warning:hover,
a.bg-warning:focus {
  background-color: #f7ecb5;
}

.bg-danger {
  background-color: #f2dede;
}

a.bg-danger:hover,
a.bg-danger:focus {
  background-color: #e4b9b9;
}

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eeeeee;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
}

ul ul,
ul ol,
ol ul,
ol ol {
  margin-bottom: 0;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}

.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

dl {
  margin-top: 0;
  margin-bottom: 20px;
}

dt,
dd {
  line-height: 1.42857;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

.dl-horizontal dd:before, .dl-horizontal dd:after {
  content: " ";
  display: table;
}

.dl-horizontal dd:after {
  clear: both;
}

@media (min-width: 0px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .dl-horizontal dd {
    margin-left: 180px;
  }
}

abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #777777;
}

.initialism {
  font-size: 90%;
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eeeeee;
}

blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
  margin-bottom: 0;
}

blockquote footer,
blockquote small,
blockquote .small {
  display: block;
  font-size: 80%;
  line-height: 1.42857;
  color: #777777;
}

blockquote footer:before,
blockquote small:before,
blockquote .small:before {
  content: '\2014 \00A0';
}

.blockquote-reverse,
blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #eeeeee;
  border-left: 0;
  text-align: right;
}

.blockquote-reverse footer:before,
.blockquote-reverse small:before,
.blockquote-reverse .small:before,
blockquote.pull-right footer:before,
blockquote.pull-right small:before,
blockquote.pull-right .small:before {
  content: '';
}

.blockquote-reverse footer:after,
.blockquote-reverse small:after,
.blockquote-reverse .small:after,
blockquote.pull-right footer:after,
blockquote.pull-right small:after,
blockquote.pull-right .small:after {
  content: '\00A0 \2014';
}

address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857;
}

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: bold;
  box-shadow: none;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857;
  word-break: break-all;
  word-wrap: break-word;
  color: #333333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container:before, .container:after {
  content: " ";
  display: table;
}

.container:after {
  clear: both;
}

@media (min-width: 0px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1050px) {
  .container {
    width: 1170px;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}

.container-fluid:after {
  clear: both;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.row:before, .row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

.col-xs-1 {
  width: 8.33333%;
}

.col-xs-2 {
  width: 16.66667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.33333%;
}

.col-xs-5 {
  width: 41.66667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.33333%;
}

.col-xs-8 {
  width: 66.66667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.33333%;
}

.col-xs-11 {
  width: 91.66667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 8.33333%;
}

.col-xs-pull-2 {
  right: 16.66667%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-4 {
  right: 33.33333%;
}

.col-xs-pull-5 {
  right: 41.66667%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-7 {
  right: 58.33333%;
}

.col-xs-pull-8 {
  right: 66.66667%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-10 {
  right: 83.33333%;
}

.col-xs-pull-11 {
  right: 91.66667%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 8.33333%;
}

.col-xs-push-2 {
  left: 16.66667%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-4 {
  left: 33.33333%;
}

.col-xs-push-5 {
  left: 41.66667%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-7 {
  left: 58.33333%;
}

.col-xs-push-8 {
  left: 66.66667%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-10 {
  left: 83.33333%;
}

.col-xs-push-11 {
  left: 91.66667%;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-offset-1 {
  margin-left: 8.33333%;
}

.col-xs-offset-2 {
  margin-left: 16.66667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.33333%;
}

.col-xs-offset-5 {
  margin-left: 41.66667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.33333%;
}

.col-xs-offset-8 {
  margin-left: 66.66667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.33333%;
}

.col-xs-offset-11 {
  margin-left: 91.66667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 0px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-1 {
    width: 8.33333%;
  }
  .col-sm-2 {
    width: 16.66667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.33333%;
  }
  .col-sm-5 {
    width: 41.66667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.33333%;
  }
  .col-sm-8 {
    width: 66.66667%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.33333%;
  }
  .col-sm-11 {
    width: 91.66667%;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-pull-1 {
    right: 8.33333%;
  }
  .col-sm-pull-2 {
    right: 16.66667%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-4 {
    right: 33.33333%;
  }
  .col-sm-pull-5 {
    right: 41.66667%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-7 {
    right: 58.33333%;
  }
  .col-sm-pull-8 {
    right: 66.66667%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-10 {
    right: 83.33333%;
  }
  .col-sm-pull-11 {
    right: 91.66667%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-push-1 {
    left: 8.33333%;
  }
  .col-sm-push-2 {
    left: 16.66667%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-4 {
    left: 33.33333%;
  }
  .col-sm-push-5 {
    left: 41.66667%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-7 {
    left: 58.33333%;
  }
  .col-sm-push-8 {
    left: 66.66667%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-10 {
    left: 83.33333%;
  }
  .col-sm-push-11 {
    left: 91.66667%;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 768px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-1 {
    width: 8.33333%;
  }
  .col-md-2 {
    width: 16.66667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.33333%;
  }
  .col-md-5 {
    width: 41.66667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.33333%;
  }
  .col-md-8 {
    width: 66.66667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.33333%;
  }
  .col-md-11 {
    width: 91.66667%;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-pull-1 {
    right: 8.33333%;
  }
  .col-md-pull-2 {
    right: 16.66667%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-4 {
    right: 33.33333%;
  }
  .col-md-pull-5 {
    right: 41.66667%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-7 {
    right: 58.33333%;
  }
  .col-md-pull-8 {
    right: 66.66667%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-10 {
    right: 83.33333%;
  }
  .col-md-pull-11 {
    right: 91.66667%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-push-1 {
    left: 8.33333%;
  }
  .col-md-push-2 {
    left: 16.66667%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-4 {
    left: 33.33333%;
  }
  .col-md-push-5 {
    left: 41.66667%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-7 {
    left: 58.33333%;
  }
  .col-md-push-8 {
    left: 66.66667%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-10 {
    left: 83.33333%;
  }
  .col-md-push-11 {
    left: 91.66667%;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333%;
  }
  .col-md-offset-2 {
    margin-left: 16.66667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333%;
  }
  .col-md-offset-5 {
    margin-left: 41.66667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333%;
  }
  .col-md-offset-8 {
    margin-left: 66.66667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333%;
  }
  .col-md-offset-11 {
    margin-left: 91.66667%;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1050px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-1 {
    width: 8.33333%;
  }
  .col-lg-2 {
    width: 16.66667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.33333%;
  }
  .col-lg-5 {
    width: 41.66667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.33333%;
  }
  .col-lg-8 {
    width: 66.66667%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.33333%;
  }
  .col-lg-11 {
    width: 91.66667%;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-pull-1 {
    right: 8.33333%;
  }
  .col-lg-pull-2 {
    right: 16.66667%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-4 {
    right: 33.33333%;
  }
  .col-lg-pull-5 {
    right: 41.66667%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-7 {
    right: 58.33333%;
  }
  .col-lg-pull-8 {
    right: 66.66667%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-10 {
    right: 83.33333%;
  }
  .col-lg-pull-11 {
    right: 91.66667%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-push-1 {
    left: 8.33333%;
  }
  .col-lg-push-2 {
    left: 16.66667%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-4 {
    left: 33.33333%;
  }
  .col-lg-push-5 {
    left: 41.66667%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-7 {
    left: 58.33333%;
  }
  .col-lg-push-8 {
    left: 66.66667%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-10 {
    left: 83.33333%;
  }
  .col-lg-push-11 {
    left: 91.66667%;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

table {
  background-color: transparent;
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777777;
  text-align: left;
}

th {
  text-align: left;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.table > caption + thead > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > th,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.table > tbody + tbody {
  border-top: 2px solid #ddd;
}

.table .table {
  background-color: #fff;
}

.table-condensed > thead > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > tfoot > tr > td {
  padding: 5px;
}

.table-bordered {
  border: 1px solid #ddd;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > tfoot > tr > td {
  border: 1px solid #ddd;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}

table col[class*="col-"] {
  position: static;
  float: none;
  display: table-column;
}

table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  float: none;
  display: table-cell;
}

.table > thead > tr > td.active,
.table > thead > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}

.table > thead > tr > td.success,
.table > thead > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th {
  background-color: #dff0d8;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}

.table > thead > tr > td.info,
.table > thead > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th {
  background-color: #d9edf7;
}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}

.table > thead > tr > td.warning,
.table > thead > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}

.table > thead > tr > td.danger,
.table > thead > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th {
  background-color: #f2dede;
}

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%;
}

@media screen and (max-width: -1px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

@-ms-viewport {
  width: device-width;
}

.visible-xs {
  display: none !important;
}

.visible-sm {
  display: none !important;
}

.visible-md {
  display: none !important;
}

.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: -1px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: -1px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: -1px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: -1px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 1049px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 1049px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 1049px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 1049px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1050px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1050px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1050px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1050px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: -1px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1049px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1050px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

html, body, div, span,
h1, h2, h3, h4, h5, h6, p, blockquote,
a, address,
em, img,
strike, strong,
ol, ul, li,
fieldset, form, label, legend,
article, aside,
figure, figcaption, footer, header,
menu, nav, output, section,
audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, figcaption, figure,
footer, header, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

a {
  text-decoration: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Proxima-Regular", sans-serif;
  color: #000;
}

@font-face {
  font-family: Lato-Regular;
  src: url(../font/Lato-Regular.ttf);
}

@font-face {
  font-family: Lato-Bold;
  src: url(../font/Lato-Bold.ttf);
}

@font-face {
  font-family: Panton-BlackCaps;
  src: url(../font/Panton-BlackCaps.otf);
}

@font-face {
  font-family: Panton-Regular;
  src: url(../font/Panton-Regular.otf);
}

@font-face {
  font-family: Panton-Black;
  src: url(../font/Panton-Black.otf);
}

@font-face {
  font-family: Panton-Light;
  src: url(../font/Panton-Light.otf);
}

@font-face {
  font-family: Panton-LightItalic;
  src: url(../font/Panton-LightItalic.otf);
}

@font-face {
  font-family: Panton-Bold;
  src: url(../font/Panton-Bold.otf);
}

@font-face {
  font-family: Panton-ExtraLight;
  src: url(../font/Panton-ExtraLight.otf);
}

@font-face {
  font-family: Panton-LightCaps;
  src: url(../font/Panton-LightCaps.otf);
}

@font-face {
  font-family: Panton-LightitalicCaps;
  src: url(../font/Panton-LightitalicCaps.otf);
}

@font-face {
  font-family: Panton-LightitalicCaps;
  src: url(../font/Panton-ExtraLight.otf);
}

@font-face {
  font-family: Panton-BoldItalic;
  src: url(../font/Panton-BoldItalic.otf);
}

@font-face {
  font-family: Panton-SemiBold;
  src: url(../font/Panton-SemiBold.otf);
}

@font-face {
  font-family: Panton-Thin;
  src: url(../font/Panton-Thin.otf);
}

@font-face {
  font-family: Panton-BlackitalicCaps;
  src: url(../font/Panton-BlackitalicCaps.otf);
}

@font-face {
  font-family: Roboto-Black;
  src: url(../font/Roboto-Black.ttf);
}

@font-face {
  font-family: Roboto-Light;
  src: url(../font/Roboto-Light.ttf);
}

@font-face {
  font-family: Roboto-MediumItalic;
  src: url(../font/Roboto-MediumItalic.ttf);
}

@font-face {
  font-family: Proxima-Regular;
  src: url(../font/proximanovasoft-regular-ttf.ttf);
}

@font-face {
  font-family: Proxima-Bold;
  src: url(../font/proximanovasoft-bold-ttf.ttf);
}

.wrapper {
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
}

.header {
  width: 100%;
  position: fixed;
  transition: all 0.3s;
  top: 0px;
  z-index: 5;
}

.header__top {
  display: flex;
  align-items: center;
  height: 45px;
  color: #fff;
  font-size: 1.1rem;
  font-family: "Roboto-Bold", sans-serif;
  border-bottom: 1px solid #fff;
  padding-left: 6%;
}

.header__top a {
  color: #fff;
}

.header__top--telephone, .header__top--contact, .header__top--clock {
  height: 100%;
  line-height: 45px;
  padding: 0 25px;
  background-repeat: no-repeat;
  background-position: left center;
}

.header__top--telephone {
  background-image: url(../images/menu-tel.svg);
  padding-left: 21px;
  border-right: 1px solid #fff;
}

.header__top--contact {
  background-image: url(../images/menu-contact.svg);
  padding-left: 60px;
  border-right: 1px solid #fff;
  background-position: left 25px center;
}

.header__top--clock {
  background-image: url(../images/menu-clock.svg);
  padding-left: 54px;
  background-position: left 25px center;
}

#offert {
  cursor: pointer;
}

.header.active {
  top: -45px;
  background-color: #2A2F35;
  transition: all 0.3s;
}

.header.active .nav__list {
  height: 65px;
}

.header.active .nav__logo img {
  height: 50px;
  transition: all 0.3s;
}

.header:hover {
  background-color: #2A2F35;
  transition: all 0.3s;
}

.nav {
  width: 100%;
  display: flex;
}

.nav__logo {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 2.5rem;
}

.nav__logo a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.nav__logo a img {
  height: 70px;
  margin: 5px 0;
  transition: all 0.3s;
}

.nav__list {
  height: 80px;
  width: 85%;
  display: flex;
  align-items: center;
}

.nav__list > ul {
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  padding-right: 5%;
  justify-content: space-between;
}

.nav__list > ul > li {
  margin: 0 1px;
  border: 1px solid transparent;
  transition: all 0.3s;
}

.nav__list > ul > li > a {
  display: block;
  padding: 10px 25px;
  color: #fff;
  font-family: "Lato-Bold", sans-serif;
  font-size: 2.3rem;
  text-transform: uppercase;
}

.nav__list > ul li.active-card {
  border: 1px solid #fff;
}

.nav__list > ul > li.current-active {
  border: 1px solid #fff;
}

.nav__list > ul li:hover {
  background-color: #252a2f;
}

.nav__list > ul li:hover > .dropdown {
  display: block;
}

.nav__list > ul li:hover > .dropdown ul li {
  opacity: 0;
}

.nav__list > ul li:hover > .dropdown ul li:nth-child(1) {
  animation-name: dropdown;
  animation-duration: 50ms;
  animation-delay: -100ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.nav__list > ul li:hover > .dropdown ul li:nth-child(2) {
  animation-name: dropdown;
  animation-duration: 100ms;
  animation-delay: -50ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.nav__list > ul li:hover > .dropdown ul li:nth-child(3) {
  animation-name: dropdown;
  animation-duration: 150ms;
  animation-delay: 0ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.nav__list > ul li:hover > .dropdown ul li:nth-child(4) {
  animation-name: dropdown;
  animation-duration: 200ms;
  animation-delay: 50ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.nav__list > ul li:hover > .dropdown ul li:nth-child(5) {
  animation-name: dropdown;
  animation-duration: 250ms;
  animation-delay: 100ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.nav__list > ul li:hover > .dropdown ul li:nth-child(6) {
  animation-name: dropdown;
  animation-duration: 300ms;
  animation-delay: 150ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.nav__list > ul li:hover > .dropdown ul li:nth-child(7) {
  animation-name: dropdown;
  animation-duration: 350ms;
  animation-delay: 200ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes dropdown {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  100% {
    opacity: 100%;
    transform: rotateX(0deg);
    opacity: 1;
  }
}

.dropdown {
  display: none;
  color: #000;
  position: absolute;
  transform: translate(-40%, 0);
}

.dropdown ul {
  display: flex;
  margin-top: 10px;
  padding: 30px;
  padding-top: 0px;
  flex-wrap: wrap;
  width: 1200px;
}

.dropdown ul li {
  width: 33.333333%;
  border: 1px solid transparent;
  border-bottom: 1px solid #1b1b1b;
  border-right: 1px solid #1b1b1b;
  background-color: #2A2F35;
  display: flex;
  align-items: center;
  padding: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.dropdown ul li a {
  display: block;
  padding: 15px 0;
  padding-left: 0px;
  padding-right: 0px;
  font-family: "Panton-LightCaps", sans-serif;
  font-size: 18px;
  color: #fff;
  text-align: center;
  transition: color 0.3s;
}

.dropdown ul li div {
  width: 100px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.dropdown ul li img {
  display: block;
  height: auto;
  width: 100%;
}

.dropdown ul li:hover a {
  color: #FFA404;
  transition: color 0.3s;
}

.hamburger-container {
  display: none;
}

.hamburger {
  padding-left: 30px;
  height: 100%;
  margin-top: -5px;
}

.menu-button-bar1, .menu-button-bar2, .menu-button-bar3 {
  position: relative;
  top: 20px;
  left: 5px;
  border-radius: 0px;
  display: block;
  background-color: #000;
  width: 30px;
  height: 3px;
  margin: 5px;
  transition: all 0.25s;
}

.animate1 {
  transition: 0.25s;
  transform: translate(0px, 8px) rotate(45deg);
}

.animate2 {
  transition: 0.25s;
  opacity: 0;
}

.animate3 {
  transition: 0.25s;
  transform: translate(0px, -8px) rotate(-45deg);
}

@media screen and (max-width: 1700px) {
  .nav__list > ul > li > a {
    padding: 7px 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 1500px) {
  .nav__logo a img {
    height: 50px;
  }
  .header.active {
    top: -30px;
  }
  .dropdown ul li a {
    font-size: 1.2rem;
    padding: 10px 0;
    padding-left: 15px;
    padding-right: 15px;
  }
  .header__top--telephone, .header__top--contact, .header__top--clock {
    padding: 0 15px;
    line-height: 32px;
  }
  .nav__logo {
    font-size: 1.7rem;
  }
  .nav__list > ul > li > a {
    padding: 5px 15px;
    font-size: 1.6rem;
  }
  .header__top {
    font-size: 0.8rem;
    height: 30px;
  }
  .header__top--telephone {
    background-size: 12px;
    padding-left: 18px;
  }
  .header__top--contact {
    background-size: 22px;
    background-position: left 15px center;
    padding-left: 43px;
  }
  .header__top--clock {
    background-size: 17px;
    background-position: left 15px center;
    padding-left: 39px;
  }
  .header.active .nav__list {
    height: 55px;
  }
  .header.active .nav__logo img {
    height: 40px;
    transition: all 0.3s;
  }
  .nav__list {
    height: 60px;
  }
  .dropdown ul {
    width: 900px;
  }
  .dropdown ul li a {
    padding-left: 10px;
    padding-right: 10px;
  }
  .dropdown ul li div {
    width: 80px;
    margin-right: 15px;
  }
  .dropdown ul li {
    border: 1px solid transparent;
    border-bottom: 1px solid #1b1b1b;
    border-right: 1px solid #1b1b1b;
  }
  .dropdown {
    transform: translate(-45%, 0);
  }
}

@media screen and (max-width: 1150px) {
  .nav__list > ul > li > a {
    font-size: 1.4rem;
    padding: 4px 12px;
  }
}

@media screen and (max-width: 1024px) {
  .nav__list > ul li:hover {
    background-color: #2A2F35;
  }
  .dropdown ul {
    width: auto;
  }
  .dropdown ul li {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: center;
  }
  .dropdown ul li {
    padding: 5px 0;
  }
  .dropdown ul li a {
    text-align: center;
  }
  .dropdown ul li div {
    display: none;
  }
  .header.active .nav__list {
    height: 55px;
  }
  .header.active .nav__logo img {
    height: 50px;
    transition: all 0.3s;
  }
  .nav {
    height: 60px;
    position: relative;
    background-color: #2A2F35;
    flex-wrap: wrap;
    max-height: 100vh;
  }
  .hamburger-container {
    display: block;
    order: 1;
    width: 20%;
    height: 100%;
  }
  .hamburger {
    padding-left: 20px;
  }
  .header__top {
    display: none;
  }
  .nav__logo {
    order: 2;
    width: 60%;
    height: 100%;
  }
  .nav__list {
    display: none;
    order: 3;
  }
  .header.active {
    top: 0;
  }
  .header.header-active .nav__list {
    overflow-y: scroll;
    display: block;
    background-color: #2A2F35;
    width: 100%;
    height: auto;
    -webkit-overflow-scrolling: touch;
  }
  .header.header-active .nav__list > ul {
    margin: 0 auto;
    overflow-y: scroll;
    display: block;
    padding: 0;
    text-align: center;
    width: auto;
    padding: 20px 0;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
  }
  .header.header-active .nav__list > ul > li {
    padding: 5px 25px;
    display: inline-block;
  }
  .header.header-active .nav__list > ul li:hover > .dropdown {
    display: none;
  }
  .dropdown {
    color: #000;
    position: static;
    transform: translate(0, 0);
  }
  .dropdown ul {
    display: block;
    margin-top: 10px;
    padding: 30px;
    padding-top: 0px;
  }
  .dropdown ul li {
    border: none;
    border-bottom: 1px solid #B2B4B6;
    background-color: #2A2F35;
  }
  .dropdown ul li a {
    display: block;
    padding: 15px 0;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "Panton-LightCaps", sans-serif;
    font-size: 18px;
    color: #B2B4B6;
  }
  .dropdown ul li:first-child {
    padding-top: 5px;
  }
  .dropdown ul li:last-child {
    border: none;
    padding-bottom: 5px;
  }
  .dropdown ul li:last-child:hover {
    border: none;
  }
  .dropdown ul li:hover {
    border: none;
    border-bottom: 1px solid #B2B4B6;
  }
  .dropdown ul li:hover a {
    color: #fff;
  }
}

@media screen and (max-width: 500px) {
  .dropdown ul li a {
    font-size: 15px;
    padding: 14px 0;
  }
  .dropdown ul {
    padding: 10px;
  }
  .header.header-active .nav__list > ul > li:last-child {
    padding-bottom: 10px;
  }
}

.footer {
  width: 100%;
  color: #fff;
  background-color: #3C3C3B;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  padding-right: 50px;
}

.footer img {
  height: 50px;
}

.footer .creative {
  display: block;
  margin: 0 auto;
}

.footer__logo {
  width: 60%;
  display: flex;
  padding-left: 5%;
  align-items: center;
  font-size: 2rem;
}

.footer__contact, .footer__open {
  width: 20%;
  border-left: 1px solid #fff;
  padding-left: 2%;
  padding-right: 10px;
  display: flex;
}

.footer__contact h2, .footer__open h2 {
  font-size: 1.7rem;
  padding-right: 30px;
}

.footer__contact p, .footer__open p {
  font-size: 1.3rem;
  padding-bottom: 5px;
}

.footer__contact a, .footer__open a {
  display: block;
  color: #fff;
  font-size: 1.3rem;
  padding-bottom: 5px;
}

.creative__tablet {
  display: none;
}

.footer .creative img {
  transform: scale(0.7) !important;
}

@media screen and (max-width: 1500px) {
  .footer {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .footer__logo {
    width: 60%;
    padding-left: 5%;
    font-size: 2rem;
  }
  .footer__contact, .footer__open {
    width: 20%;
    padding-left: 2%;
    padding-right: 10px;
  }
  .footer__contact h2, .footer__open h2 {
    font-size: 1.3rem;
    padding-right: 20px;
  }
  .footer__contact p, .footer__open p {
    font-size: 1rem;
    padding-bottom: 5px;
  }
  .footer__contact a, .footer__open a {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    padding-top: 40px;
    padding-bottom: 40px;
    flex-wrap: wrap;
  }
  .footer__logo {
    width: 100%;
    padding-left: 4%;
    font-size: 2rem;
    padding-bottom: 30px;
  }
  .footer__contact, .footer__open {
    border: none;
    width: 100%;
    padding-left: 4%;
    padding-right: 10px;
    padding-bottom: 30px;
  }
  .footer__contact h2, .footer__open h2 {
    font-size: 1.3rem;
    width: 150px;
  }
  .footer__contact p, .footer__open p {
    font-size: 1rem;
    padding-bottom: 5px;
  }
  .footer__open {
    padding-bottom: 0;
  }
  .footer .creative {
    display: none;
  }
  .creative__tablet {
    display: block;
    margin-top: 30px;
    padding-left: 4%;
  }
}

.main-slider.swiper-container {
  width: 100%;
  height: 800px;
}

.main-slider-wrapper {
  position: relative;
}

.main-slider .swiper-slide1 {
  background-image: url(../images/main-slide.jpg);
}

.main-slider .swiper-slide2 {
  background-image: url(../images/banner-about.jpg);
}

.main-slider .swiper-slide3 {
  background-image: url(../images/main-slide.jpg);
}

.main-slider .swiper-slide4 {
  background-image: url(../images/banner-about.jpg);
}

.main-slider .swiper-slide {
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.main-slider .swiper-slide h2 {
  font-size: 3.6rem;
  padding-bottom: 45px;
  text-transform: uppercase;
  z-index: 2;
  padding-right: 15%;
  padding-left: 15%;
  text-align: center;
}

.main-slider .swiper-slide p {
  font-size: 3.6rem;
  font-family: "Panton-LightItalic", sans-serif;
  padding-bottom: 100px;
  z-index: 2;
  padding-right: 15%;
  padding-left: 15%;
  text-align: center;
}

.main-slider .swiper-slide a {
  color: #fff;
  font-size: 2.5rem;
  background-color: #FFA404;
  border-radius: 25px;
  padding: 10px 15px;
  z-index: 11;
  transition: all 0.3s;
}

.main-slider .swiper-slide a:hover {
  background-color: #000;
  color: #FFA404;
  transition: all 0.3s;
}

.main-slider .swiper-slide .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(255, 255, 255, 0) 50%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.main-slider.swiper-container-vertical > .swiper-pagination-bullets {
  left: 50px;
  right: auto;
}

.main-slider .swiper-pagination-bullet {
  opacity: 1;
}

.main-slider.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  background-image: url(../images/main-slider-pagination.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.main-slider.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet-active {
  background-image: url(../images/main-slider-pagination.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.main-slider .swiper-pagination-bullet {
  width: 60px;
  height: 60px;
  background: transparent;
}

.main-button {
  width: 100%;
  position: relative;
  margin-top: -35px;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
}

.main-button__circle {
  height: 70px;
  width: 1px;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 4;
}

.main-slider.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  position: relative;
}

.main-slider.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  position: relative;
}

#myCanvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1500px) {
  .main-button {
    margin-top: -25px;
    padding-bottom: 5px;
  }
  .main-button__circle {
    width: 50px;
    height: 50px;
    background-size: 20px;
  }
  .main-slider.swiper-container {
    width: 100%;
    height: 550px;
  }
  .main-slider .swiper-slide h2 {
    font-size: 2.6rem;
    padding-bottom: 30px;
  }
  .main-slider .swiper-slide p {
    font-size: 2.6rem;
    padding-bottom: 70px;
  }
  .main-slider .swiper-slide a {
    font-size: 1.8rem;
  }
  .main-slider.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    background-size: 20px;
  }
  .main-slider.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet-active {
    background-size: 20px;
  }
  .main-slider .swiper-pagination-bullet {
    width: 45px;
    height: 45px;
  }
}

@media screen and (max-width: 1024px) {
  .main-button {
    margin-top: -15px;
  }
  .main-button__circle {
    width: 40px;
    height: 40px;
    background-size: 18px;
  }
  .main-slider.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    background-image: url(../images/main-slider-pagination.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .main-slider.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet-active {
    background-image: url(../images/main-slider-pagination.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .main-slider.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    background-size: 15px;
  }
  .main-slider.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet-active {
    background-size: 15px;
  }
  .main-slider .swiper-pagination-bullet {
    width: 35px;
    height: 35px;
  }
  .main-slider.swiper-container {
    width: 100%;
    height: 450px;
    margin-top: 60px;
  }
  .main-slider .swiper-slide h2 {
    font-size: 2.3rem;
    padding-bottom: 15px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .main-slider .swiper-slide p {
    font-size: 2.3rem;
    padding-bottom: 50px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .main-slider .swiper-slide a {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 800px) {
  .main-slider.swiper-container {
    width: 100%;
    height: 400px;
    margin-top: 60px;
  }
}

@media screen and (max-width: 500px) {
  .main-button {
    padding-bottom: 10px;
  }
}

.row-custom-margin {
  margin-left: -22px;
  margin-right: -22px;
}

.news-container {
  margin-top: 40px;
  margin-bottom: 40px;
}

.news:hover {
  background-size: 110%;
  transition: all 0.3s;
}

.news {
  height: 370px;
  display: flex;
  align-items: flex-end;
  border: 7px solid #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-left: 0;
  padding-right: 0;
  transition: all 0.3s;
  background-size: 100%;
  visibility: hidden;
}

.news1 {
  background-image: url(../images/main-news1.jpg);
}

.news2 {
  background-image: url(../images/main-news2.jpg);
}

.news3 {
  background-image: url(../images/main-news3.jpg);
}

.news4 {
  background-image: url(../images/main-news4.jpg);
  height: 740px;
}

.news4 .news__overlay {
  height: 24.5%;
  text-transform: uppercase;
}

.news4 .news__overlay h2, .news4 .news__overlay h3 {
  font-size: 3.2rem;
  width: 100%;
}

.news4 .news__overlay p {
  width: 100%;
}

.news4 a {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: #FFA404;
  border-radius: 50px;
  font-size: 3.1rem;
  font-family: "Lato-Bold", sans-serif;
  text-transform: uppercase;
  padding: 15px 20px;
  color: #fff;
  transition: all 0.3s;
}

.news4 a:hover {
  color: #FFA404;
  background-color: #000;
  transition: all 0.3s;
}

.news__overlay {
  height: 50%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.66);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.2rem;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  transition: all 0.3s;
}

.news__overlay h2 {
  font-family: "Panton-Bold", sans-serif;
  transition: all 0.3s;
  width: 100%;
}

.news__overlay p {
  width: 100%;
}

.news:hover .news__overlay {
  transition: all 0.3s;
  background-color: rgba(0, 0, 0, 0.86);
}

@media screen and (max-width: 1500px) {
  .news-container {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .news {
    height: 270px;
    border: 5px solid #fff;
  }
  .news__overlay {
    font-size: 16px;
  }
  .news4 {
    height: 540px;
  }
  .news4 .news__overlay h2, .news4 .news__overlay h3 {
    font-size: 2.2rem;
  }
  .news4 a {
    font-size: 2rem;
    padding: 10px 15px;
  }
}

@media screen and (max-width: 1024px) {
  .news {
    background-size: cover;
    visibility: visible;
  }
  .news4 {
    height: 270px;
  }
  .news4 .news__overlay {
    height: 50%;
  }
}

@media screen and (max-width: 500px) {
  .news-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .news4 .news__overlay h2, .news4 .news__overlay h3 {
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 360px) {
  .news4 .news__overlay {
    padding-top: 20px;
    height: 50%;
  }
}

#main-site-form {
  background-image: url(../images/ludzik-odbicie.png);
}

.form {
  margin-top: 40px;
  background-image: url(../images/ludzik.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  padding-top: 50px;
  padding-bottom: 40px;
  margin-bottom: -20px;
}

.form .container-fluid {
  padding-left: 3%;
  padding-right: 15%;
}

.form input, .form textarea {
  width: 100%;
  border: 1px solid #575756;
  font-size: 2rem;
  font-family: "Proxima-Regular", sans-serif;
  color: #808080;
  padding: 15px 20px;
}

.form input {
  margin-bottom: 20px;
  height: 60px;
  outline: none;
}

.form input:hover, .form input:focus, .form textarea:hover, .form textarea:focus {
  outline: 1px solid #FFA404;
  border-color: #FFA404;
}

.form input:placeholder {
  opacity: 1;
  font-size: 2rem;
  font-family: "Proxima-Regular", sans-serif;
  color: #808080;
}

.form textarea {
  resize: none;
  height: 140px;
}

.form input[type="text"], .form input[type="email"], .form textarea {
  appearance: none;
}

.form input[type="submit"] {
  width: 250px;
  height: auto;
  border: none;
  border-radius: 50px;
  color: #fff;
  background-color: #FFA404;
  font-size: 2.1rem;
  font-family: "Lato-Bold", sans-serif;
  padding: 24px;
  cursor: pointer;
  float: right;
  margin-right: 50px;
  margin-top: 10px;
  transition: all 0.3s;
  -webkit-appearance: none;
  appearance: none;
  border: 2px solid #fff;
}

.form input[type="submit"]:hover {
  border: 2px solid #000;
  color: #FFA404;
  background-color: #fff;
  outline: none;
  transition: all 0.3s;
}

@media screen and (max-width: 1500px) {
  .form {
    margin-top: 20px;
    background-image: url(../images/ludzik.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    padding-top: 40px;
    padding-bottom: 30px;
    margin-bottom: -13px;
  }
  .form input {
    height: 50px;
    margin-bottom: 15px;
  }
  .form textarea {
    height: 115px;
  }
  .form input, .form textarea {
    font-size: 1.35rem;
  }
  .form input[type="submit"] {
    font-size: 1.5rem;
    padding: 15px;
    width: 170px;
    margin-right: 35px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 1024px) {
  #main-site-form {
    background: none;
  }
  .form .container-fluid {
    padding-left: 3%;
    padding-right: 3%;
  }
  .form {
    margin-top: 0px;
    background-image: none;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .form input[type="submit"] {
    margin-right: 0;
    margin-top: 20px;
    margin-bottom: 15px;
    padding: 14px;
  }
}

.main-offer-container {
  margin-top: 40px;
  margin-bottom: 40px;
}

.main-offer1 {
  background-image: url(../images/main-offer1.jpg);
}

.main-offer2 {
  background-image: url(../images/main-offer2.jpg);
}

.main-offer3 {
  background-image: url(../images/main-offer3.jpg);
}

.main-offer4 {
  background-image: url(../images/main-offer4.jpg);
}

.main-offer5 {
  background-image: url(../images/main-offer5.jpg);
}

.main-offer6 {
  background-image: url(../images/main-offer6.jpg);
}

.row-offer-reset {
  margin-left: -20px;
  margin-right: -20px;
}

.main-offer {
  height: 370px;
  border: 5px solid #fff;
  padding: 0;
  position: relative;
}

.main-offer__overlay {
  color: #fff;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.44);
  font-size: 3.2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.main-offer__overlay h3 {
  text-transform: uppercase;
  margin: 27% 0 10px 0;
  font-family: "Panton-LightCaps", sans-serif;
}

.main-offer__overlay h3 span {
  font-family: "Panton-Bold", sans-serif;
}

.main-offer__overlay p {
  font-size: 1.75rem;
  padding-left: 50px;
  padding-right: 50px;
}

.main-offer .main-offer__overlay::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 2px solid #fff;
  box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.2);
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale3d(1.4, 1.4, 1);
  transform: scale3d(1.4, 1.4, 1);
}

.main-offer h3 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

.main-offer p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.main-offer:hover h3 {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.main-offer:hover .main-offer__overlay::before,
.main-offer:hover p {
  display: block;
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.main-offer:hover .main-offer__overlay {
  background-color: rgba(0, 0, 0, 0.74);
}

.main-offer:hover img {
  opacity: 0.4;
}

@media screen and (max-width: 1500px) {
  .main-offer p {
    font-size: 1.25rem;
    padding-left: 40px;
    padding-right: 40px;
  }
  .main-offer .main-offer__overlay::before {
    position: absolute;
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
    border: 2px solid #fff;
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.2);
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale3d(1.4, 1.4, 1);
    transform: scale3d(1.4, 1.4, 1);
  }
  .main-offer-container {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .row-offer-reset {
    margin-left: -18px;
    margin-right: -18px;
  }
  .main-offer {
    height: 270px;
    border: 3px solid #fff;
  }
  .main-offer__overlay {
    font-size: 2.2rem;
  }
  .main-offer__overlay h3 {
    font-family: "Panton-LightCaps", sans-serif;
  }
  .main-offer__overlay h3 span {
    font-family: "Panton-Bold", sans-serif;
  }
}

@media screen and (max-width: 1500px) {
  .main-offer-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .main-offer .main-offer__overlay::before, .main-offer p {
    display: none;
  }
  .main-offer__overlay {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .main-offer__overlay h3 {
    margin: 0;
  }
  .main-offer:hover h3 {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  .main-offer:hover .main-offer__overlay::before,
  .main-offer:hover p {
    display: none;
  }
}

.message-box {
  display: flex;
  flex-direction: column;
  font-size: 2.1rem;
  line-height: 2.8rem;
}

.message-box h2 {
  font-family: "Panton-Bold", sans-serif;
}

.message-box div {
  margin: 5px 0;
  width: 100%;
  height: 1px;
  background-color: #2A2F35;
}

.message-box a {
  color: #2A2F35;
}

.main-map {
  width: 100%;
  display: flex;
  height: 550px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.main-map__google {
  width: 60%;
}

.main-map__image {
  width: 40%;
  background-image: url(../images/main-map.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 1500px) {
  .main-map {
    height: 400px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .message-box {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
}

@media screen and (max-width: 1024px) {
  .main-map {
    height: 400px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .main-map__google {
    width: 100%;
  }
  .main-map__image {
    display: none;
  }
  .message-box {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
}

@media screen and (max-width: 500px) {
  .main-map {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.main-references {
  margin-top: 40px;
  margin-bottom: 80px;
  min-height: 200px;
  display: flex;
  align-items: center;
  width: 100%;
}

.main-references .swiper-button-next, .main-references .swiper-button-prev {
  height: 40px;
  width: 40px;
  background-size: cover;
}

.main-references .swiper-button-next {
  background-image: url(../images/arrow-right.svg);
  right: 20px;
}

.main-references .swiper-button-prev {
  background-image: url(../images/arrow-left.svg);
  left: 20px;
}

.reference-slide.swiper-slide-active {
  padding-left: 60px;
  transition: all 0.3s;
}

.reference-slide.swiper-slide-active + .reference-slide + .reference-slide {
  padding-right: 60px;
  transition: all 0.3s;
}

.reference-slide {
  display: flex;
  justify-content: center;
  transition: all 0.3s;
}

.reference-slide__image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  padding-right: 20px;
}

.reference-slide__image img {
  display: block;
  max-width: 100%;
  height: auto;
}

.reference-slide__content {
  max-width: 300px;
  display: flex;
  flex-direction: column;
}

.reference-slide__content h3 {
  font-family: "Panton-Bold", sans-serif;
  font-size: 2.3rem;
  padding-bottom: 15px;
  text-transform: uppercase;
}

.reference-slide__content p {
  font-size: 1.6rem;
}

.reference-slide__content a {
  font-family: "Panton-Bold", sans-serif;
  font-size: 1.6rem;
  color: #000;
}

.main-references.swiper-container-horizontal > .swiper-scrollbar {
  left: 0%;
  width: 100%;
  height: 1px;
  background: black;
}

.main-references .swiper-scrollbar {
  border-radius: 0;
}

.main-references .swiper-scrollbar-drag {
  border-radius: 0;
  height: 8px;
  top: -4px;
  background-color: #FFA404;
}

@media screen and (max-width: 1500px) {
  .reference-slide.swiper-slide-active {
    padding-left: 55px;
    transition: all 0.3s;
  }
  .reference-slide.swiper-slide-active + .reference-slide + .reference-slide {
    padding-right: 55px;
    transition: all 0.3s;
  }
  .main-references .swiper-button-next, .main-references .swiper-button-prev {
    height: 35px;
    width: 35px;
    background-size: cover;
  }
  .main-references {
    margin-top: 25px;
    margin-bottom: 25px;
    padding-bottom: 25px;
    min-height: 170px;
  }
  .reference-slide__image {
    width: 100px;
    padding-right: 15px;
  }
  .reference-slide__image img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  .reference-slide__content {
    max-width: 250px;
  }
  .reference-slide__content h3 {
    font-size: 1.7rem;
    padding-bottom: 10px;
  }
  .reference-slide__content p {
    font-size: 1.2rem;
  }
  .reference-slide__content a {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 1024px) {
  .main-references {
    padding-bottom: 50px;
  }
  .reference-slide.swiper-slide-active {
    padding-left: 0px;
    transition: all 0.3s;
  }
  .reference-slide.swiper-slide-active + .reference-slide + .reference-slide {
    padding-right: 0px;
    transition: all 0.3s;
  }
  .main-references .swiper-button-next, .main-references .swiper-button-prev {
    top: 88%;
  }
  .main-references .swiper-button-next {
    right: 44%;
  }
  .main-references .swiper-button-prev {
    left: 44%;
  }
}

@media screen and (max-width: 500px) {
  .main-references .swiper-button-next {
    right: 38%;
  }
  .main-references .swiper-button-prev {
    left: 38%;
  }
  .main-references .swiper-button-next, .main-references .swiper-button-prev {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 350px) {
  .main-references .swiper-button-next {
    right: 35%;
  }
  .main-references .swiper-button-prev {
    left: 35%;
  }
  .main-references .swiper-button-next, .main-references .swiper-button-prev {
    width: 30px;
    height: 30px;
  }
}

.main-partners {
  margin-top: 40px;
  margin-bottom: 40px;
}

#carousel_inner {
  width: 100%;
  overflow: hidden;
  padding: 0px;
}

#carousel_ul {
  position: relative;
  left: 0px;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  width: 3000px;
}

#carousel_ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 60px;
  padding: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

#carousel_ul li a {
  margin-left: 40px;
  margin-right: 40px;
}

#carousel_ul li img {
  cursor: hand;
  border: 0px;
  max-height: 60px;
  width: auto;
  filter: grayscale(100%);
  transition: all 0.3s;
}

#carousel_ul li img:hover {
  filter: grayscale(0%);
  transition: all 0.3s;
}

@media screen and (max-width: 1500px) {
  #carousel_ul {
    width: 2100px;
  }
  #carousel_ul li img {
    max-height: 40px;
  }
  .main-partners {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 1024px) {
  #carousel_ul li img {
    filter: grayscale(0%);
  }
}

@media screen and (max-width: 500px) {
  .main-partners {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  #carousel_ul li img {
    max-height: 30px;
  }
  #carousel_ul li {
    height: 30px;
  }
  #carousel_ul {
    width: 1500px;
  }
}

.about {
  margin-top: 10px;
}

.about__img1 {
  background-image: url(../images/about1.jpg);
}

.about__img2 {
  background-image: url(../images/about2.jpg);
}

.about__img3 {
  background-image: url(../images/about3.jpg);
}

.image {
  height: 600px;
  position: relative;
  cursor: pointer;
}

.image__front, .image__back {
  backface-visibility: hidden;
  transition: transform 0.4s;
}

.image__back {
  transform: rotateY(-180deg);
}

.image:hover .image__front {
  transform: rotateY(-180deg);
}

.image:hover .image__back {
  transform: rotateY(0);
}

.image__background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.about__img {
  height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: all 0.3s;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}

.about__img:hover {
  background-size: 110%;
  transition: all 0.3s;
}

.padding0 {
  padding-left: 0;
  padding-right: 0;
}

.about__background {
  background-size: cover;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
}

.about__img:hover .about__background {
  transform: scale(1.1);
  transition: all 0.3s;
}

.about__content {
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px 3%;
}

.about__content--img-container {
  height: 70px;
  margin-bottom: 20px;
  display: flex;
}

.about__content--img-container img {
  display: block;
}

.about__content h2 {
  font-family: "Panton-Bold", sans-serif;
  font-size: 2.4rem;
  padding-bottom: 50px;
}

.about__content p {
  text-align: center;
  font-family: "Panton-LightItalic", sans-serif;
  font-size: 2.1rem;
  line-height: 3.1rem;
  padding-bottom: 20px;
  width: 100%;
}

.about__content p.about__number {
  font-family: "Panton-Italic", sans-serif;
  line-height: 2.1rem;
}

.about__slider {
  height: 750px;
}

.about__slider--slide {
  height: 600px;
  display: flex;
}

.about__slider--slide-img {
  height: 600px;
  width: 50%;
  margin-right: 10px;
  background-image: url(../images/about__slider-back.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.about__slider--slide-text {
  height: 600px;
  width: 50%;
  margin-left: 10px;
  background-color: #F4F4F4;
}

.slide-text {
  padding: 30px 0;
}

.slide-text__element {
  height: 50%;
  display: flex;
  overflow: hidden;
}

.slide-text__element-img {
  width: 150px;
  display: flex;
  justify-content: center;
}

.slide-text__element-img figure {
  height: 100px;
  width: 100px;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.slide-text__element-text {
  width: 85%;
  padding-right: 50px;
}

.slide-text__element-text h2 {
  padding: 30px 0;
  font-family: "Panton-LightCaps", sans-serif;
  font-size: 3.6rem;
  text-transform: uppercase;
}

.slide-text__element-text h2 span {
  font-family: "Panton-Bold", sans-serif;
}

.slide-text__element-text p {
  font-family: "Panton-Light", sans-serif;
  font-size: 1.6rem;
  line-height: 3rem;
}

.slide-text__element-img1 {
  background-image: url(../images/about-slide-icon1.jpg);
}

.slide-text__element-img2 {
  background-image: url(../images/about-slide-icon2.jpg);
}

.about__slider.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 85px;
}

.about__slider.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 6px;
}

.about__slider .swiper-pagination-bullet {
  background: #fff;
  height: 20px;
  width: 20px;
  opacity: 1;
  border: 1px solid #000;
}

.about__slider .swiper-pagination-bullet-active {
  background: #FFA404;
  border: 1px solid #FFA404;
}

@media screen and (max-width: 1500px) {
  .about__slider {
    height: 550px;
  }
  .about__slider--slide, .about__slider--slide-img, .about__slider--slide-text {
    height: 450px;
  }
  .about__slider.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 55px;
  }
  .about__slider .swiper-pagination-bullet {
    height: 15px;
    width: 15px;
  }
  .slide-text__element-img figure {
    height: 75px;
    width: 75px;
  }
  .slide-text__element-img {
    width: 115px;
  }
  .slide-text__element-text h2 {
    padding: 25px 0 15px 0;
    font-size: 2.5rem;
  }
  .slide-text {
    padding: 20px 0;
  }
  .slide-text__element-text p {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .about__img:hover .about__background {
    transform: scale(1);
    transition: all 0.3s;
  }
  .about__slider--slide {
    height: 600px;
    display: flex;
    flex-wrap: wrap;
  }
  .about__slider--slide-img {
    width: 100%;
    margin-right: 0px;
  }
  .about__slider--slide-text {
    width: 100%;
    margin-left: 0px;
  }
  .about__slider--slide-img {
    height: 350px;
  }
  .about__slider--slide-text, .about__slider--slide, .about__slider {
    height: auto;
  }
  .slide-text__element {
    height: auto;
  }
  .slide-text__element-text p {
    padding-bottom: 15px;
  }
  .about__slider.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 15px;
  }
  .slide-text {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 600px) {
  .slide-text__element-img {
    display: none;
  }
  .slide-text__element-text {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
  .slide-text {
    padding-top: 0;
  }
}

.about__counter {
  margin-top: 100px;
  margin-bottom: 100px;
}

.about__counter h2 {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 3.6rem;
  font-family: "Panton-Bold", sans-serif;
  padding-bottom: 60px;
}

.about__counter h3 {
  font-size: 5.6rem;
  font-family: "Panton-Bold", sans-serif;
  padding: 30px 0;
  margin-bottom: 40px;
  position: relative;
  color: #575756;
  width: 100%;
  text-align: center;
}

.about__counter h3:after {
  content: "";
  width: 100px;
  height: 4px;
  background-color: #FFA404;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, 0);
}

.about__counter p {
  padding-top: 10px;
  font-size: 3.3rem;
  font-family: "Panton-LightItalic", sans-serif;
}

.about__counter-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__counter-box--image {
  height: 100px;
  display: flex;
  margin-bottom: 20px;
}

.about__counter-box--image img {
  max-height: 100%;
  width: auto;
}

@media screen and (max-width: 1500px) {
  .about__counter {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .about__counter h2 {
    font-size: 2.5rem;
    padding-bottom: 40px;
  }
  .about__counter-box--image {
    height: 70px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  .about__counter-box--image img.exp {
    max-height: 45px;
  }
  .about__counter h3 {
    font-size: 4rem;
    padding: 18px 0;
    margin-bottom: 25px;
  }
  .about__counter h3:after {
    width: 70px;
    height: 3px;
  }
  .about__counter p {
    font-size: 2.3rem;
    padding-top: 5px;
    text-align: center;
  }
}

@media screen and (max-width: 1024px) {
  .about__counter h3 {
    font-size: 3rem;
  }
  .about__counter p {
    font-size: 2rem;
  }
  .about__counter-box--image {
    height: 60px;
  }
}

@media screen and (max-width: 760px) {
  .about__counter {
    margin-top: 0px;
    margin-bottom: 50px;
  }
  .about__counter-box {
    padding-bottom: 50px;
  }
  .about__counter-box:last-of-type {
    padding-bottom: 0px;
  }
  .about__counter-box--image {
    height: 50px;
  }
  .about__counter-box--image img.exp {
    max-height: 40px;
  }
  .about__counter h3 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1500px) {
  .about__img {
    height: 400px;
  }
  .about__content {
    height: 400px;
    padding: 30px 3%;
  }
  .about__content--img-container {
    height: 50px;
    margin-bottom: 15px;
  }
  .about__content img {
    transform: scale(0.8);
  }
  .about__content h2 {
    font-family: "Panton-Bold", sans-serif;
    font-size: 1.7rem;
    padding-bottom: 40px;
  }
  .about__content p {
    text-align: center;
    font-family: "Panton-LightItalic", sans-serif;
    font-size: 1.5rem;
    line-height: 2.2rem;
    padding-bottom: 20px;
  }
  .about__content p.about__number {
    font-family: "Panton-Italic", sans-serif;
    line-height: 1.6rem;
  }
}

@media screen and (max-width: 500px) {
  .about__img {
    height: 300px;
  }
  .about__content {
    height: 300px;
  }
}

.gallery {
  width: 92%;
  margin: 0 4%;
}

.gallery__categories {
  padding: 70px 0;
  padding-bottom: 95px;
}

.gallery__categories ul {
  width: 100%;
  display: flex;
  position: relative;
}

.gallery__categories ul li {
  position: relative;
  padding-left: 30px;
  padding-right: 60px;
}

.gallery__categories ul li a {
  text-decoration: none;
  font-size: 2.8rem;
  color: #3c3c3b;
  position: relative;
  transition: color 0.3s;
}

.gallery__categories ul li a.gallery-active {
  color: #FFA404;
}

.gallery__categories ul li a.gallery-active:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 50px 0 50px;
  border-color: #FFA404 transparent transparent transparent;
  position: absolute;
  top: 65px;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, 0);
}

.gallery__categories ul li a.gallery-active:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 50px 0 50px;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  top: 60px;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, 0);
  z-index: 2;
}

.gallery__categories ul li a:hover {
  color: #FFA404;
  transition: color 0.3s;
}

.gallery__categories ul:after {
  content: "";
  width: 100%;
  height: 4px;
  background-color: #FFA404;
  position: absolute;
  bottom: -35px;
}

.gallery__content {
  width: 102%;
  margin-left: -1%;
  margin-right: -1%;
  flex-wrap: wrap;
  margin-bottom: 50px;
  float: left;
}

.gallery__content:after {
  content: "";
  clear: both;
}

.gallery__content--element {
  float: left;
  margin: 1%;
  height: 380px;
  width: 31.3333%;
  background-image: url(../images/gallery-image.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  outline: none;
}

.gallery__content--element:after {
  content: "";
  clear: both;
}

.gallery__content--element .overlay {
  height: 100%;
  width: 100%;
  border: 25px solid #FFA404;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.3s;
}

.gallery__content--element .overlay img {
  margin-bottom: 30px;
  transition: all 0.3s;
  max-width: 100%;
}

.gallery__content--element .overlay h2 {
  font-family: "Panton-Bold", sans-serif;
  font-size: 2.8rem;
  color: #fff;
  transition: all 0.3s;
  padding: 0 40px;
  text-align: center;
}

.gallery__content--element:hover .overlay {
  opacity: 1;
  transition: all 0.3s;
}

@media screen and (max-width: 1500px) {
  .gallery__categories ul li {
    padding-right: 40px;
  }
  .gallery__categories {
    padding: 50px 0;
    padding-bottom: 60px;
  }
  .gallery__categories ul li a {
    font-size: 2rem;
  }
  .gallery__categories ul:after {
    height: 3px;
    bottom: -20px;
  }
  .gallery__categories ul li a.gallery-active:before {
    top: 39px;
    border-width: 25px 40px 0 40px;
  }
  .gallery__categories ul li a.gallery-active:after {
    top: 35px;
    border-width: 25px 40px 0 40px;
  }
  .gallery__content--element {
    height: 270px;
  }
  .gallery__content--element .overlay h2 {
    font-size: 2rem;
  }
  .gallery__content--element .overlay img {
    transform: scale(0.7);
    margin-bottom: 20px;
  }
  .gallery__content--element .overlay {
    border-width: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .gallery__content--element {
    width: 48%;
    height: 300px;
  }
  .gallery__categories ul {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .gallery__categories ul li {
    padding-right: 30px;
  }
}

@media screen and (max-width: 800px) {
  .gallery__content--element {
    width: 48%;
    height: 230px;
  }
}

@media screen and (max-width: 500px) {
  .gallery__categories ul {
    flex-direction: column;
    text-align: center;
  }
  .gallery__categories ul li a.gallery-active:after, .gallery__categories ul li a.gallery-active:before {
    display: none;
  }
  .gallery__categories ul li {
    padding: 10px;
  }
  .gallery__categories {
    padding-top: 30px;
    padding-bottom: 50px;
  }
  .gallery__content--element {
    width: 98%;
  }
  .gallery__content {
    margin-bottom: 30px;
  }
}

.offert {
  width: 92%;
  margin: 0 4%;
  display: flex;
  padding: 50px 0;
}

.offert h2, .offert h3 {
  font-family: "Panton-Bold", sans-serif;
  font-size: 2.4rem;
  padding-bottom: 50px;
  visibility: hidden;
}

.offert-contact {
  width: 27%;
  margin-right: 7%;
}

.offert-contact__box {
  margin-top: 40px;
  border: 1px solid #000;
}

.offert-contact__box img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.offert-contact__box--data {
  background-color: #FFA404;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  padding-right: 10px;
}

.offert-contact__box--data a {
  color: #fff;
  font-size: 2.7rem;
  line-height: 4.6rem;
}

.offert-article {
  width: 66%;
}

.offert-article p {
  font-family: "Panton-Light", sans-serif;
  font-size: 2rem;
  line-height: 3.8rem;
}

.offert-article__background {
  margin: 50px 0;
  height: 380px;
  background-image: url(../images/offert-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.offert-article__content {
  display: flex;
  flex-wrap: wrap;
}

.offert-article__content-list {
  width: 52.5%;
}

.offert-article__content-list ul li.list__element a.list__link {
  display: block;
  font-family: "Panton-SemiBold", sans-serif;
  color: #1D1D1B;
  font-size: 2.7rem;
  padding: 20px 0;
  padding-left: 75px;
  background-image: url(../images/offert-list.svg);
  background-repeat: no-repeat;
  background-position: left center;
}

.offert-article__content-list ul li.list__element a.offert-active {
  background-image: url(../images/offert-list-active.svg);
  background-position: left center;
}

.offert-article__content-list ul li.list__element .list__producent {
  padding-left: 75px;
  padding-bottom: 20px;
}

.offert-article__content-list ul li.list__element .list__producent--description {
  font-size: 1.8rem;
  line-height: 3rem;
}

.offert-article__content-list ul li.list__element .list__producent--name {
  font-family: "Panton-Bold", sans-serif;
  color: #000;
  font-size: 2rem;
  transition: color 0.3s;
}

.offert-article__content-list ul li.list__element .list__producent--name:hover {
  color: #FFA404;
  transition: color 0.3s;
}

.offert-article__content-list ul li.list__element .list__producent:last-child {
  padding-bottom: 0;
}

.offert-article__content-list:last-child {
  margin-right: 0%;
  width: 47.5%;
}

.offert-article__content-text {
  width: 40%;
}

.list-second {
  margin-top: 59px;
}

.offert-article__content .line {
  margin-bottom: 20px;
}

.offert-article__content .line h2 {
  visibility: visible;
  font-size: 2.4rem;
  font-family: "Panton-Bold", sans-serif;
  padding-bottom: 0;
  padding-left: 0;
}

.offert-article__content-list .list {
  padding-top: 25px;
}

.offert__recommended {
  width: 100%;
  display: block;
  background-color: #FFA404;
  margin-top: 20px;
  font-size: 2.7rem;
  color: #fff;
  text-align: center;
  padding: 15px;
  border: 1px solid #000;
  transition: all 0.3s;
}

.offert__recommended:hover {
  background-color: #fff;
  color: #000;
  transition: all 0.3s;
}

.offert-contact h2, .offert-contact h3, .offert-article__heading, .offert-contact__box, .offert-article__background {
  visibility: hidden;
}

.offert-article__content-list h2 {
  visibility: visible;
}

.offert-contact h2:first-child {
  padding-bottom: 10px;
}

.offert-contact h3:nth-child(2) {
  padding-bottom: 95px;
}

@media screen and (max-width: 1500px) {
  .offert-contact h2:first-child {
    padding-bottom: 0px;
  }
  .offert-contact h3:nth-child(2) {
    padding-bottom: 65px;
  }
  .offert-article__content .line {
    margin-bottom: 15px;
  }
  .offert-article__content .line h2 {
    font-size: 1.8rem;
  }
  .list-second {
    margin-top: 57px;
  }
  .offert__recommended {
    margin-top: 15px;
    font-size: 1.8rem;
    padding: 13px;
  }
  .offert h2, .offert h3 {
    font-size: 1.7rem;
    line-height: 2.7rem;
    padding-bottom: 30px;
  }
  .offert-article p {
    font-size: 1.4rem;
    line-height: 2.7rem;
  }
  .offert-contact__box--data {
    padding: 25px 15px;
  }
  .offert-contact__box--data a {
    font-size: 1.8rem;
    line-height: 3.2rem;
  }
  .offert-article__background {
    height: 270px;
    margin: 35px 0;
  }
  .offert-contact__box {
    margin-top: 15px;
  }
  .offert-article__content-list ul li.list__element a.list__link {
    padding: 17px 0;
    padding-left: 55px;
    background-size: 35px;
    font-size: 1.9rem;
  }
  .offert-article__content-list ul li.list__element .list__producent {
    padding-left: 55px;
  }
  .offert-article__content-list ul li.list__element .list__producent--description {
    font-size: 1.3rem;
    line-height: 2.2rem;
  }
  .offert-article__content-list ul li.list__element .list__producent--name {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .offert-contact h3:nth-child(2) {
    padding-bottom: 20px;
  }
  .offert-contact h2, .offert-contact h3, .offert-article__heading, .offert-contact__box, .offert-article__background {
    visibility: visible;
  }
  .offert {
    flex-wrap: wrap;
  }
  .offert-contact {
    width: 100%;
    margin-right: 0;
  }
  .offert-article {
    width: 100%;
    padding-top: 30px;
  }
  .offert-contact__box img {
    display: none;
  }
  .offert h2, .offert h3 {
    padding-bottom: 15px;
  }
  .offert-contact__box {
    margin-top: 5px;
  }
  .offert-contact__box--data {
    padding: 20px;
  }
  .list-second {
    margin-top: 42px;
  }
  /*  .offert-article__content-list {
    width: 47.5%;
    margin-right: 2.5%;
  }
  .offert-article__content-text {
    width: 47.5%;
    margin-left: 2.5%;
  }*/
}

@media screen and (max-width: 600px) {
  .offert-article__content-list .list {
    padding-top: 0px;
  }
  .offert-article__content {
    flex-wrap: wrap;
  }
  .offert-article__content-list {
    width: 100%;
    margin-right: 0;
    order: 2;
    padding-top: 30px;
  }
  .offert-article__content-text {
    width: 100%;
    margin-left: 0;
    order: 1;
  }
  .offert-contact__box {
    margin-left: -4.5%;
    margin-right: -4.5%;
    border: none;
  }
  .offert {
    padding: 30px 0;
  }
  .offert-article__background {
    height: 170px;
  }
  .offert-article__content-list ul li.list__element .list__producent {
    padding-left: 0;
  }
  .line.list-second {
    display: none;
  }
  .list.list-second {
    margin-top: 0;
    padding-top: 0;
  }
  .offert-article__content-list:last-child {
    padding-top: 0;
    width: 100%;
  }
  .offert-article__content-list {
    padding-top: 20px;
  }
}

@media screen and (max-width: 359px) {
  .offert-contact__box--data a {
    font-size: 1.7rem;
  }
}

.download {
  width: 100%;
}

.download-list {
  height: 100px;
  border-bottom: 1px solid #000;
  background-color: #F4F4F4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-list li {
  height: 100%;
  border-right: 1px solid #000;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  text-align: center;
}

.download-list li a {
  color: #3C3C3B;
  font-size: 2rem;
  font-family: "Panton-Bold", sans-serif;
  text-transform: uppercase;
  transition: all 0.3s;
}

.download-list li:hover, .download-list li.download-active {
  background-color: #FFA404;
  transition: all 0.3s;
}

.download-list li:hover a, .download-list li.download-active a {
  color: #fff;
  transition: all 0.3s;
}

.download-list li:first-child {
  border-left: 1px solid #000;
}

.download-content {
  padding: 100px 0;
  width: 92%;
  margin-left: 4%;
  margin-right: 4%;
}

.download-content__box div {
  margin-bottom: 20px;
  width: 100%;
  background-color: #F4F4F4;
  display: flex;
  align-items: center;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  height: 55px;
}

.download-content__box div a {
  display: flex;
  border: none;
  width: 200px;
  border: 2px solid transparent;
  height: 100%;
  background-color: #FFA404;
  border-radius: 50px;
  background-image: url(../images/download-sign.svg);
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.3s;
}

.download-content__box div a:hover {
  background-color: #fff;
  background-image: url(../images/download-sing-yellow.svg);
  transition: all 0.3s;
  border: 2px solid #000;
}

.download-content__box div p {
  padding-left: 30px;
  font-family: "Panton-Bold", sans-serif;
  color: #3C3C3B;
  font-size: 2.1rem;
}

.download-content__box div:last-child {
  margin-bottom: 0;
}

.download-content__box:not(:first-child) {
  display: none;
}

@media screen and (max-width: 1500px) {
  .download-list {
    height: 75px;
  }
  .download-list li {
    width: 220px;
  }
  .download-list li a {
    font-size: 1.45rem;
  }
  .download-content {
    padding: 70px 0;
  }
  .download-content__box div p {
    font-size: 1.5rem;
  }
  .download-content__box div {
    height: 40px;
    margin-bottom: 15px;
  }
  .download-content__box div a {
    width: 120px;
    background-size: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .download-list li {
    width: 25%;
  }
  .download-list li a {
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .download-content__box div p {
    padding-left: 15px;
    font-size: 1.1rem;
  }
  .download-content__box div {
    height: 55px;
  }
  .download-list {
    height: auto;
    flex-wrap: wrap;
  }
  .download-list li {
    border: none;
    width: 100%;
    padding: 10px 0;
  }
  .download-content {
    padding: 40px 0;
  }
  .download-list li:first-child {
    border: none;
  }
}

.line-padding {
  padding: 40px 0;
  overflow: hidden;
}

.contact-wrapper {
  padding-left: 4%;
  padding-right: 4%;
  overflow: hidden;
}

.contact__adress {
  padding-top: 30px;
  padding-bottom: 30px;
}

.contact__adress-element {
  padding: 0;
}

.contact__adress-element h2 {
  font-family: "Panton-Bold", sans-serif;
  font-size: 2.8rem;
  min-height: 80px;
  text-transform: uppercase;
}

.contact__adress-element p, .contact__adress-element a {
  font-size: 2.4rem;
  line-height: 4rem;
  color: #3C3C3B;
  display: block;
}

.contact__adress-element:nth-child(4), .contact__adress-element:nth-child(5), .contact__adress-element:nth-child(6) {
  padding-top: 50px;
}

.form.contact-form {
  background-position: left center;
  padding-top: 0;
}

.form.contact-form .container-fluid {
  padding-left: 14%;
  padding-right: 0;
}

.form.contact-form input[type="submit"] {
  margin-right: 0;
  padding: 15px;
}

/*.line.line-contact:before  {
  position: absolute;
  right: 0;
  content: "";
  width: 4%;
  height: 10px;
  background-color: #fff;
  z-index: 2;
}

.line.line-contact.reverse:before  {
  position: absolute;
  left: 0;
  content: "";
  width: 4%;
  height: 10px;
  background-color: #fff;
  z-index: 2;
}*/
.line.line-contact:after {
  width: 100%;
}

.line.line-contact h2 {
  padding-left: 0;
}

.line.line-contact.reverse h2 {
  padding-right: 0;
  padding-left: 4%;
}

@media screen and (max-width: 1500px) {
  .contact__adress-element h2 {
    font-size: 2rem;
    min-height: 60px;
  }
  .contact__adress-element p, .contact__adress-element a {
    font-size: 1.7rem;
    line-height: 3rem;
  }
  .line-padding {
    padding: 30px 0;
    overflow: hidden;
  }
  .contact__adress {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .form.contact-form .container-fluid {
    padding-left: 19%;
  }
}

@media screen and (max-width: 1024px) {
  .form.contact-form .container-fluid {
    padding-left: 0;
  }
  .contact__adress .row {
    display: flex;
    flex-wrap: wrap;
  }
  .contact__adress-element:nth-child(3), .contact__adress-element:nth-child(4), .contact__adress-element:nth-child(5), .contact__adress-element:nth-child(6) {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) {
  .contact__adress-element {
    width: 100%;
    padding-top: 30px;
  }
  .contact__adress-element:nth-child(1) {
    padding-top: 0;
  }
  .line-padding {
    padding: 20px 0;
  }
  .contact__adress-element h2 {
    min-height: auto;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 500px) {
  .contact__adress-element h2 {
    font-size: 1.8rem;
  }
  .contact__adress-element p, .contact__adress-element a {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
  .contact__adress {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 350px) {
  .contact__adress-element p, .contact__adress-element a {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}

.article {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.article__heading {
  font-family: "Panton-Bold", sans-serif;
  font-size: 3.2rem;
  color: #FFA404;
  padding: 30px 20px;
  text-transform: uppercase;
  text-align: center;
}

.article__data {
  font-family: "Panton-Italic", sans-serif;
  font-size: 2.5rem;
  color: #808080;
  text-align: center;
}

.article__text {
  font-size: 2rem;
  text-align: center;
  padding: 45px 10%;
  line-height: 4rem;
  width: 100%;
}

.article__link {
  display: block;
  background-color: #FFA404;
  color: #fff;
  font-family: "Lato-Bold", sans-serif;
  font-size: 2rem;
  width: 250px;
  height: 70px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  transition: all 0.3s;
}

.article__link:hover {
  background-color: #000;
  color: #FFA404;
  transition: all 0.3s;
}

.article__background {
  width: 100%;
  height: 650px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.article__background1 {
  background-image: url(../images/news1.jpg);
}

.article__background2 {
  background-image: url(../images/news2.jpg);
}

.article__background3 {
  background-image: url(../images/news3.jpg);
}

.article-site-control {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  margin: 40px 0;
}

.article-site-control > a {
  color: #808080;
  font-size: 2.5rem;
  font-family: "Panton-Italic", sans-serif;
  transition: all 0.3s;
}

.article-site-control > a:hover {
  color: #000;
  transition: all 0.3s;
}

.article-site-control__numbers {
  height: 100%;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.article-site-control__numbers a {
  color: #3C3C3B;
  font-size: 3rem;
  padding: 10px;
  transition: all 0.3s;
}

.article-site-control__numbers a:hover {
  color: #808080;
  transition: all 0.3s;
}

.article-site-control__numbers a.article-active {
  color: #FFA404;
}

@media screen and (max-width: 1500px) {
  .article__heading {
    font-size: 2.3rem;
    padding: 25px 20px;
  }
  .article__data {
    font-size: 1.75rem;
  }
  .article__text {
    font-size: 1.4rem;
    line-height: 3rem;
    padding: 30px 10%;
  }
  .article__link {
    font-size: 1.4rem;
    width: 170px;
    height: 50px;
    margin-bottom: 30px;
  }
  .article__background {
    height: 450px;
  }
  .article-site-control > a {
    font-size: 1.7rem;
  }
  .article-site-control__numbers a {
    font-size: 2.1rem;
    padding: 8px;
  }
  .article-site-control__numbers {
    padding-left: 20px;
    padding-right: 20px;
  }
  .article-site-control {
    height: 50px;
    margin: 30px 0;
  }
}

@media screen and (max-width: 1024px) {
  .article__text {
    padding: 30px 5%;
  }
  .article__background {
    height: 300px;
  }
}

@media screen and (max-width: 500px) {
  .article__data {
    font-size: 1.5rem;
  }
  .article__heading {
    padding: 25px 15px;
    padding-bottom: 15px;
  }
  .article__text {
    line-height: 2.5rem;
  }
  .article__background {
    height: 200px;
  }
  .article__text {
    padding: 25px 5%;
  }
  .article__link {
    width: 150px;
    height: 45px;
    margin-bottom: 25px;
  }
  .article-site-control > a {
    padding: 20px 0;
    font-size: 1.3rem;
  }
  .article-site-control {
    height: 45px;
  }
}

@media screen and (max-width: 359px) {
  .article-site-control__numbers {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 10px;
  }
}

.news-page {
  margin-top: 150px;
}

.news-page .article__heading {
  color: #3C3C3B;
}

.news-page .article__data {
  text-align: center;
}

.news-page p {
  text-align: left;
  padding: 0 10%;
  padding-bottom: 20px;
}

.news-page p:last-of-type {
  padding-bottom: 40px;
}

.news-page__buttons {
  width: 92%;
  margin: 0 4%;
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #000;
}

.news-page__buttons a {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lato-Bold", sans-serif;
  font-size: 1.7rem;
  color: #3c3c3b;
  border: 1px solid #000;
  border-radius: 50px;
  height: 60px;
  width: 215px;
  transition: all 0.3s;
}

.news-page__buttons a:hover {
  color: #FFA404;
  border-color: #FFA404;
  transition: all 0.3s;
}

.news-page__buttons a:hover svg path {
  fill: #FFA404;
  stroke: #FFA404;
}

.news-page__buttons--more {
  display: flex;
}

.news-page__buttons--more a {
  margin-right: 40px;
}

.news-page__buttons--share a svg {
  margin-right: 15px;
}

.news-page__prev {
  width: 92%;
  margin: 0 4%;
  padding: 50px 0;
  display: inline-block;
}

.news-page__prev a {
  font-family: "Lato-Light", sans-serif;
  font-size: 2.4rem;
  color: #3C3C3B;
  display: flex;
  align-items: center;
  width: 300px;
  transition: all 0.3s;
}

.news-page__prev a span {
  font-size: 4rem;
  padding-right: 20px;
}

.news-page__prev a:hover {
  color: #FFA404;
  transition: all 0.3s;
}

.header-grey {
  background-color: #2A2F35;
}

.news-page-more {
  width: 92%;
  margin: 0 4%;
  display: flex;
  flex-wrap: wrap;
  color: #3c3c3b;
}

.news-page-more h2 {
  width: 100%;
  font-size: 3.4rem;
  padding-bottom: 40px;
}

.news-page-more__element {
  width: 49%;
  color: #3c3c3b;
  transition: all 0.3s;
}

.news-page-more__element-background {
  height: 380px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.news-page-more__element h3 {
  font-family: "Panton-Bold", sans-serif;
  font-size: 2.5rem;
  text-align: center;
  padding: 20px;
  text-transform: uppercase;
}

.news-page-more__element p {
  font-family: "Panton-Italic", sans-serif;
  font-size: 1.5rem;
  text-align: center;
  padding-bottom: 20px;
}

.news-page-more__element:hover {
  opacity: 0.7;
  transition: all 0.3s;
}

.news-page-more__element:first-of-type {
  margin-right: 1%;
}

.news-page-more__element:first-of-type .news-page-more__element-background {
  background-image: url(../images/more1.jpg);
}

.news-page-more__element:last-of-type {
  margin-left: 1%;
}

.news-page-more__element:last-of-type .news-page-more__element-background {
  background-image: url(../images/more2.jpg);
}

.news-page-discuss {
  width: 92%;
  margin: 0 4%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
  color: #3c3c3b;
}

.news-page-discuss h2 {
  width: 100%;
  font-size: 3.4rem;
  padding-bottom: 40px;
}

.news-page-discuss .discuss {
  width: 100%;
}

@media screen and (max-width: 1500px) {
  .news-page {
    margin-top: 90px;
  }
  .news-page p {
    text-align: left;
    padding: 0 8%;
    padding-bottom: 15px;
  }
  .news-page p:last-of-type {
    padding-bottom: 30px;
  }
  .news-page__buttons {
    padding: 30px 0;
  }
  .news-page__buttons--more a {
    margin-right: 25px;
  }
  .news-page__buttons a {
    font-size: 1.2rem;
    width: 150px;
    height: 45px;
  }
  .news-page__buttons--share a svg {
    height: 30px;
    margin-right: 5px;
  }
  .news-page__prev {
    padding: 35px 0;
  }
  .news-page__prev a {
    font-size: 1.7rem;
  }
  .news-page__prev a span {
    font-size: 2.9rem;
    padding-right: 10px;
  }
  .news-page-more h2, .news-page-discuss h2 {
    font-size: 2.4rem;
    padding-bottom: 30px;
  }
  .news-page-more__element-background {
    height: 270px;
  }
  .news-page-more__element h3 {
    font-size: 1.8rem;
    padding: 15px;
  }
  .news-page-more__element p {
    padding-bottom: 15px;
    font-size: 11px;
  }
}

@media screen and (max-width: 1024px) {
  .news-page {
    margin-top: 70px;
  }
}

@media screen and (max-width: 800px) {
  .news-page-more__element-background {
    height: 200px;
  }
}

@media screen and (max-width: 767px) {
  .news-page__buttons {
    flex-wrap: wrap;
  }
  .news-page__buttons--more {
    width: 100%;
    flex-wrap: wrap;
  }
  .news-page__buttons--share {
    margin-top: 20px;
  }
}

@media screen and (max-width: 500px) {
  .news-page__buttons--more a {
    margin: 0 auto;
    margin-bottom: 15px;
    width: 100%;
  }
  .news-page__buttons--share {
    width: 100%;
    margin-top: 0;
  }
  .news-page__buttons--share a {
    padding-right: 25px;
  }
  .news-page__buttons a {
    margin-top: 0;
    width: 100%;
  }
  .news-page p {
    padding: 0 5%;
    padding-bottom: 15px;
  }
  .news-page-more__element {
    width: 100%;
  }
  .news-page-more__element:last-of-type, .news-page-more__element:first-of-type {
    margin: 0;
  }
  .news-page-discuss {
    padding-top: 25px;
  }
}

.line {
  width: 100%;
  align-items: center;
  position: relative;
  display: block;
  float: left;
}

.line h1, .line h2 {
  padding-right: 4%;
  padding-left: 4%;
  background-color: #fff;
  z-index: 2;
  font-family: "Panton-LightCaps", sans-serif;
  font-size: 3.8rem;
  text-transform: uppercase;
  display: inline-block;
}

.line h1 span, .line h2 span {
  font-family: "Panton-Bold", sans-serif;
}

.line:after {
  z-index: -1;
  display: block;
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background-color: #000;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.reverse {
  justify-content: flex-end;
}

.reverse h2, .reverse h1 {
  float: right;
}

.reverse h1 {
  padding-right: 0;
}

.reverse h2:after {
  content: "";
  display: block;
  clear: both;
}

.clear {
  clear: both;
}

@media screen and (max-width: 1500px) {
  .line h1, .line h2 {
    font-size: 2.6rem;
  }
}

/*@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}*/
.banner-about {
  background-image: url(../images/banner-about.jpg);
}

.banner-galeria {
  background-image: url(../images/banner-galeria.jpg);
}

.banner-offert {
  background-image: url(../images/banner-offert.jpg);
}

.banner-download {
  background-image: url(../images/banner-download.jpg);
}

.banner-contact {
  background-image: url(../images/banner-contact.jpg);
}

.banner-news {
  background-image: url(../images/banner-news.jpg);
}

.banner {
  animation: fadeIn 2s forwards;
  height: 580px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
}

.banner h1 {
  color: #fff;
  font-family: "Lato-Bold", sans-serif;
  font-size: 4.8rem;
  z-index: 2;
  padding: 40px 0;
  padding-top: 130px;
  width: 40%;
  text-align: center;
  margin-top: 50px;
  visibility: hidden;
}

.banner p {
  color: #fff;
  width: 90%;
  font-size: 2.5rem;
  text-align: center;
  z-index: 2;
}

.banner a {
  margin-top: 30px;
  z-index: 2;
  background-color: #FFA404;
  color: #fff;
  font-size: 2.5rem;
  padding: 10px 15px;
  border-radius: 50px;
  transition: all 0.3s;
  visibility: hidden;
}

.banner a:hover {
  background-color: #000;
  color: #FFA404;
  transition: all 0.3s;
}

.banner__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.banner h1 {
  position: relative;
  overflow: hidden;
  padding: 0.5em 0;
}

.banner h1::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #fff;
  content: '';
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.banner:hover h1::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.banner p {
  padding-top: 30px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.banner:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.input::placeholder {
  color: red;
}

.no-scroll {
  overflow: hidden !important;
  height: 100% !important;
}

@media screen and (max-width: 1500px) {
  .banner {
    height: 400px;
  }
  .banner h1 {
    font-size: 3.4rem;
    padding: 20px 10px;
    padding-top: 70px;
    text-align: center;
    margin-top: 0;
  }
  .banner a {
    font-size: 18px;
    padding: 10px 15px;
  }
  .banner p {
    font-size: 1.7rem;
    text-align: center;
    padding-top: 20px;
  }
  .banner a {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .banner {
    height: 400px;
  }
  .banner h1 {
    font-size: 3.4rem;
    padding: 20px 10px;
    padding-top: 70px;
    text-align: center;
    margin-top: 0;
    visibility: visible;
  }
  .banner a {
    font-size: 18px;
    padding: 10px 15px;
    visibility: visible;
  }
  .banner p {
    display: none;
  }
  .banner h1 {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .banner h1::after {
    display: none;
  }
}

.arrow {
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  border-radius: 100%;
  position: fixed;
  background-color: #FFA404;
  z-index: 999;
  background-image: url(../images/arrow-up.svg);
  background-size: contain;
  background-repeat: no-repeat;
  display: none;
  opacity: 0.7;
  transition: all 0.3s;
}

.arrow:hover {
  opacity: 1;
  transition: all 0.3s;
}

@media screen and (max-width: 1500px) {
  .arrow {
    width: 40px;
    height: 40px;
    bottom: 30px;
    right: 30px;
  }
}

@media screen and (max-widtH: 1300px) {
  .arrow {
    right: 20px;
  }
}

@media screen and (max-width: 500px) {
  .arrow {
    bottom: 15px;
    right: 10px;
  }
}
