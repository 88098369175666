.main-partners {
  margin-top: 40px;
  margin-bottom: 40px;
}

#carousel_inner {
	width: 100%;
	overflow: hidden;
	padding: 0px;
}

#carousel_ul {
	position: relative;
	left: 0px;
	list-style-type: none;
	margin: 0px;
	padding: 0px;
  display: flex;
  width: 3000px;
}

#carousel_ul li{
  display: flex;
  justify-content: center;
  align-items: center;
	width: 25%;
	height: 60px;
	padding: 0px;
	margin-top: 10px;
	margin-bottom: 10px;
	margin-left: 5px;
	margin-right: 5px;
}

#carousel_ul li a{
  margin-left: 40px;
  margin-right: 40px;
}
#carousel_ul li img {
	cursor: hand;
	border: 0px;
  max-height: 60px;
  width: auto;
  filter: grayscale(100%);
  transition: all 0.3s;
}


#carousel_ul li img:hover {
  filter: grayscale(0%);
  transition: all 0.3s;
}

@media screen and (max-width: 1500px) {
  #carousel_ul {
    width: 2100px;
  }
  #carousel_ul li img {
    max-height: 40px;
  }
  .main-partners {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 1024px) {
  #carousel_ul li img {
    filter: grayscale(0%);
  }
}

@media screen and (max-width: 500px) {
  .main-partners {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  #carousel_ul li img {
    max-height: 30px;
  }
  #carousel_ul li{
    height: 30px;
  }
  #carousel_ul {
    width: 1500px;
  }
}
