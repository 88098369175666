.main-offer-container {
  margin-top: 40px;
  margin-bottom: 40px;
}
.main-offer1 {
  background-image: url(../images/main-offer1.jpg);
}
.main-offer2 {
  background-image: url(../images/main-offer2.jpg);
}
.main-offer3 {
  background-image: url(../images/main-offer3.jpg);
}
.main-offer4 {
  background-image: url(../images/main-offer4.jpg);
}
.main-offer5 {
  background-image: url(../images/main-offer5.jpg);
}
.main-offer6 {
  background-image: url(../images/main-offer6.jpg);
}
.row-offer-reset {
  margin-left: -20px;
  margin-right: -20px;
}
.main-offer {
  height: 370px;
  border: 5px solid $white;
  padding: 0;
  position: relative;
  &__overlay {
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    //align-items: center;
    color: $white;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.44);  ///rgba(52, 56, 50, 0.44);
    font-size: 3.2rem;
    text-align: center;
    position: relative;
    overflow: hidden;
    text-align: center;
    h3 {
      text-transform: uppercase;
      margin: 27% 0 10px 0;
      font-family: $Panton-LightCaps;
       span {
          font-family: $Panton-Bold;
       }
    }
    p {
      font-size: 1.75rem;
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}

.main-offer .main-offer__overlay::before {
	position: absolute;
	top: 30px;
	right: 30px;
	bottom: 30px;
	left: 30px;
	border: 2px solid #fff;
	box-shadow: 0 0 0 30px rgba(255,255,255,0.2);
	content: '';
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: scale3d(1.4,1.4,1);
	transform: scale3d(1.4,1.4,1);
}

.main-offer h3 {
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
}

.main-offer p {
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: scale(1.5);
	transform: scale(1.5);
}

.main-offer:hover h3 {
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
}

.main-offer:hover .main-offer__overlay::before,
.main-offer:hover p {
  display: block;
	opacity: 1;
	-webkit-transform: scale3d(1,1,1);
	transform: scale3d(1,1,1);
}

.main-offer:hover .main-offer__overlay {
	background-color: rgba(0, 0, 0, 0.74);
}

.main-offer:hover img {
	opacity: 0.4;
}

@media screen and (max-width: 1500px) {
  .main-offer p {
    font-size: 1.25rem;
    padding-left: 40px;
    padding-right: 40px;
  }
  .main-offer .main-offer__overlay::before {
  	position: absolute;
  	top: 20px;
  	right: 20px;
  	bottom: 20px;
  	left: 20px;
  	border: 2px solid #fff;
  	box-shadow: 0 0 0 30px rgba(255,255,255,0.2);
  	content: '';
  	opacity: 0;
  	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  	transition: opacity 0.35s, transform 0.35s;
  	-webkit-transform: scale3d(1.4,1.4,1);
  	transform: scale3d(1.4,1.4,1);
  }
  .main-offer-container {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .row-offer-reset {
    margin-left: -18px;
    margin-right: -18px;
  }
  .main-offer {
    height: 270px;
    border: 3px solid $white;
    &__overlay {
      font-size: 2.2rem;
      h3 {
        font-family: $Panton-LightCaps;
         span {
            font-family: $Panton-Bold;
         }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .main-offer-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .main-offer .main-offer__overlay::before, .main-offer p {
    display: none;
  }
  .main-offer__overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
      margin: 0;
    }
  }
  .main-offer:hover h3 {
  	-webkit-transform: scale(0.9);
  	transform: scale(0.9);
  }

  .main-offer:hover .main-offer__overlay::before,
  .main-offer:hover p {
    display: none;
  }
}
