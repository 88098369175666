.news-page {
  margin-top: 150px;
  .article__heading {
    color: #3C3C3B;
  }
  .article__data {
    text-align: center;
  }
  p {
    text-align: left;
    padding: 0 10%;
    padding-bottom: 20px;
  }
  p:last-of-type {
    padding-bottom: 40px;
  }
}

.news-page__buttons {
  width: 92%;
  margin: 0 4%;
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid $black;
  a {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $Lato-Bold;
    font-size: 1.7rem;
    color: #3c3c3b;
    border: 1px solid $black;
    border-radius: 50px;
    height: 60px;
    width: 215px;
    transition: all 0.3s;
  }
  a:hover {
    color: $yellow;
    border-color: $yellow;
    transition: all 0.3s;
    svg path {
      fill: $yellow;
      stroke: $yellow;
    }
  }
  &--more {
    display: flex;
    a {
     margin-right: 40px;
    }
  }
  &--share a {
    svg {
      margin-right: 15px;
    }
  }
}

.news-page__prev {
  width: 92%;
  margin: 0 4%;
  padding: 50px 0;
  display: inline-block;
  a {
    font-family: $Lato-Light;
    font-size: 2.4rem;
    color: #3C3C3B;
    display: flex;
    align-items: center;
    width: 300px;
    transition: all 0.3s;
    span {
      font-size: 4rem;
      padding-right: 20px;
    }
  }
  a:hover {
    color: $yellow;
    transition: all 0.3s;
  }
}

.header-grey {
  background-color: $grey;
}

.news-page-more {
  width: 92%;
  margin: 0 4%;
  //padding: 50px 0;
  display: flex;
  flex-wrap: wrap;
  color: #3c3c3b;
  h2 {
    width: 100%;
    font-size: 3.4rem;
    padding-bottom: 40px;
  }
  &__element {
    width: 49%;
    color: #3c3c3b;
    transition: all 0.3s;
    &-background {
      height: 380px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    h3 {
      font-family: $Panton-Bold;
      font-size: 2.5rem;
      text-align: center;
      padding: 20px;
      text-transform: uppercase;
    }
    p {
      font-family: $Panton-Italic;
      font-size: 1.5rem;
      text-align: center;
      padding-bottom: 20px;
    }
  }
  &__element:hover {
    opacity: 0.7;
    transition: all 0.3s;
  }
  &__element:first-of-type {
    margin-right: 1%;
    .news-page-more__element-background {
      background-image: url(../images/more1.jpg);
    }
  }
  &__element:last-of-type {
    margin-left: 1%;
    .news-page-more__element-background {
      background-image: url(../images/more2.jpg);
    }
  }
}

.news-page-discuss {
  width: 92%;
  margin: 0 4%;
  //padding: 50px 0;
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
  color: #3c3c3b;
  h2 {
    width: 100%;
    font-size: 3.4rem;
    padding-bottom: 40px;
  }
  .discuss {
    width: 100%;
  }
}

@media screen and (max-width: 1500px) {
  .news-page {
    margin-top: 90px;
    p {
      text-align: left;
      padding: 0 8%;
      padding-bottom: 15px;
    }
    p:last-of-type {
      padding-bottom: 30px;
    }
  }
  .news-page__buttons {
    padding: 30px 0;
  }
  .news-page__buttons--more a {
    margin-right: 25px;
  }
  .news-page__buttons a {
    font-size: 1.2rem;
    width: 150px;
    height: 45px;
  }
  .news-page__buttons--share a svg {
    height: 30px;
    margin-right: 5px;
  }
  .news-page__prev {
    padding: 35px 0;
  }
  .news-page__prev a {
    font-size: 1.7rem;
    span {
      font-size: 2.9rem;
      padding-right: 10px;
    }
  }
  .news-page-more h2, .news-page-discuss h2 {
    font-size: 2.4rem;
    padding-bottom: 30px;
  }
  .news-page-more__element-background {
    height: 270px;
  }
  .news-page-more__element h3 {
    font-size: 1.8rem;
    padding: 15px;
  }
  .news-page-more__element p {
    padding-bottom: 15px;
    font-size: 11px;
  }
}

@media screen and (max-width: 1024px) {
  .news-page {
    margin-top: 70px;
  }
}
@media screen and (max-width: 800px) {
  .news-page-more__element-background {
    height: 200px;
  }
}

@media screen and (max-width: 767px) {
  .news-page__buttons {
    flex-wrap: wrap;
  }
  .news-page__buttons--more {
    width: 100%;
    flex-wrap: wrap;
  }
  .news-page__buttons--share {
    margin-top: 20px;
  }
}

@media screen and (max-width: 500px) {
  .news-page__buttons--more a {
    margin: 0 auto;
    margin-bottom: 15px;
    width: 100%;
  }
  .news-page__buttons--share {
    width: 100%;
    margin-top: 0;
    a {
      padding-right: 25px;
    }
  }
  .news-page__buttons a {
    margin-top: 0;
    width: 100%;
  }
  .news-page p {
    padding: 0 5%;
    padding-bottom: 15px;
  }
  .news-page-more__element {
    width: 100%;
  }
  .news-page-more__element:last-of-type, .news-page-more__element:first-of-type {
    margin: 0;
  }
  .news-page-discuss {
    padding-top: 25px;
  }
}
