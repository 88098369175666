.download {
  width: 100%;
  &-list {
    height: 100px;
    border-bottom: 1px solid $black;
    background-color: #F4F4F4;
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      height: 100%;
      border-right: 1px solid $black;
      width: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.3s;
      text-align: center;
      a {
        color: #3C3C3B;
        font-size: 2rem;
        font-family: $Panton-Bold;
        text-transform: uppercase;
        transition: all 0.3s;
      }
    }
    li:hover, li.download-active {
      background-color: $yellow;
      transition: all 0.3s;
      a {
        color: $white;
        transition: all 0.3s;
      }
    }
    li:first-child {
      border-left: 1px solid $black;
    }
  }
  &-content {
    padding: 100px 0;
    width: 92%;
    margin-left: 4%;
    margin-right: 4%;
    &__box {
      div {
        margin-bottom: 20px;
        width: 100%;
        background-color: #F4F4F4;
        display: flex;
        align-items: center;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        height: 55px;
        a {
          display: flex;
          border: none;
          width: 200px;
          border: 2px solid transparent;
          height: 100%;
          background-color: $yellow;
          border-radius: 50px;
          background-image: url(../images/download-sign.svg);
          background-repeat: no-repeat;
          background-position: center;
          transition: all 0.3s;
        }
        a:hover {
          background-color: $white;
          background-image: url(../images/download-sing-yellow.svg);
          transition: all 0.3s;
          border: 2px solid $black;
        }
        p {
          padding-left: 30px;
          font-family: $Panton-Bold;
          color: #3C3C3B;
          font-size: 2.1rem;
        }
      }
      div:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.download-content__box:not(:first-child) {
  display: none;
}

@media screen and (max-width: 1500px) {
  .download-list {
    height: 75px;
  }
  .download-list li {
    width: 220px;
  }
  .download-list li a {
    font-size: 1.45rem;
  }
  .download-content {
    padding: 70px 0;
  }
  .download-content__box div p {
    font-size: 1.5rem;
  }
  .download-content__box div {
    height: 40px;
    margin-bottom: 15px;
  }
  .download-content__box div a {
    width: 120px;
    background-size: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .download-list li {
    width: 25%;
    a {
      text-align: center;
    }
  }
}

@media screen and (max-width: 600px) {
  .download-content__box div p {
    padding-left: 15px;
    font-size: 1.1rem;
  }
  .download-content__box div {
    height: 55px;
  }
  .download-list {
    height: auto;
    flex-wrap: wrap;
    li {
      border: none;
      width: 100%;
      padding: 10px 0;
    }
  }
  .download-content {
    padding: 40px 0;
  }
  .download-list li:first-child {
    border: none;
  }
}
