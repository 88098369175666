/*@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}*/

.banner-about {
  background-image: url(../images/banner-about.jpg);
}
.banner-galeria {
  background-image: url(../images/banner-galeria.jpg);
}
.banner-offert {
  background-image: url(../images/banner-offert.jpg);
}
.banner-download {
  background-image: url(../images/banner-download.jpg);
}
.banner-contact {
  background-image: url(../images/banner-contact.jpg);
}
.banner-news {
  background-image: url(../images/banner-news.jpg);
}
.banner {
  animation: fadeIn 2s forwards;
  height: 580px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  h1 {
    color: $white;
    font-family: $Lato-Bold;
    font-size: 4.8rem;
    z-index: 2;
    padding: 40px 0;
    padding-top: 130px;
    width: 40%;
    text-align: center;
    margin-top: 50px;
    visibility: hidden;
  }
  p {
    color: $white;
    width: 90%;
    font-size: 2.5rem;
    text-align: center;
    z-index: 2;
  }
  a {
    margin-top: 30px;
    z-index: 2;
    background-color: $yellow;
    color: $white;
    font-size: 2.5rem;
    padding: 10px 15px;
    border-radius: 50px;
    transition: all 0.3s;
    visibility: hidden;
  }
  a:hover {
    background-color: $black;
    color: $yellow;
    transition: all 0.3s;
  }
}

.banner__overlay {
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.7);
}


.banner h1 {
	position: relative;
	overflow: hidden;
	padding: 0.5em 0;
}

.banner h1::after {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 3px;
	background: #fff;
	content: '';
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: translate3d(-100%,0,0);
	transform: translate3d(-100%,0,0);
}

.banner:hover h1::after {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

.banner p {
  padding-top: 30px;
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(100%,0,0);
	transform: translate3d(100%,0,0);
}

.banner:hover p {
	opacity: 1;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

.input {
  &::placeholder {
    color:red;
  }
}

.no-scroll {
  overflow: hidden !important;
  height: 100% !important;
}

@media screen and (max-width: 1500px) {
  .banner {
    height: 400px;
    h1 {
      font-size: 3.4rem;
      padding: 20px 10px;
      padding-top: 70px;
      text-align: center;
      margin-top: 0;
    }
    a {
      font-size: 18px;
      padding: 10px 15px;
    }
  }
  .banner {
    p {
      font-size: 1.7rem;
      text-align: center;
      padding-top: 20px;
    }
    a {
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .banner {
    height: 400px;
    h1 {
      font-size: 3.4rem;
      padding: 20px 10px;
      padding-top: 70px;
      text-align: center;
      margin-top: 0;
      visibility: visible;
    }
    a {
      font-size: 18px;
      padding: 10px 15px;
      visibility: visible;
    }
  }
  .banner {
    p {
      display: none;
    }
    h1 {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .banner h1::after {
  	display: none;
  }
}
