#main-site-form {
  background-image: url(../images/ludzik-odbicie.png);
}
.form {
  margin-top: 40px;
  background-image: url(../images/ludzik.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  padding-top: 50px;
  padding-bottom: 40px;
  margin-bottom: -20px;
  .container-fluid {
    padding-left: 3%;
    padding-right: 15%;
  }
  input,textarea {
    width: 100%;
    border: 1px solid #575756;
    font-size: 2rem;
    font-family: $Proxima-Regular;
    color: #808080;
    padding: 15px 20px;
  }
  input {
    margin-bottom: 20px;
    height: 60px;
    outline: none;
  }
  input:hover, input:focus, textarea:hover, textarea:focus {
    outline: 1px solid $yellow;
    border-color: $yellow;
  }
  input:placeholder {
    opacity: 1;
    font-size: 2rem;
    font-family: $Proxima-Regular;
    color: #808080;
  }
  textarea {
    resize: none;
    height: 140px;
  }
  input[type="text"], input[type="email"], textarea {
    appearance:none;
  }
  input[type="submit"] {
    width: 250px;
    height: auto;
    border: none;
    border-radius: 50px;
    color: $white;
    background-color: $yellow;
    font-size: 2.1rem;
    font-family: $Lato-Bold;
    padding: 24px;
    cursor: pointer;
    float: right;
    margin-right: 50px;
    margin-top: 10px;
    transition: all 0.3s;
    -webkit-appearance:none;
    appearance:none;
    border: 2px solid $white;
  }
  input[type="submit"]:hover {
    border: 2px solid $black;
    color: $yellow;
    background-color: $white;
    outline: none;
    transition: all 0.3s;
  }
}

@media screen and (max-width: 1500px) {
  .form {
    margin-top: 20px;
    background-image: url(../images/ludzik.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    padding-top: 40px;
    padding-bottom: 30px;
    margin-bottom: -13px;
    input {
      height: 50px;
      margin-bottom: 15px;
    }
    textarea {
      height: 115px;
    }
    input,textarea {
      font-size: 1.35rem;
    }
    input[type="submit"] {
      font-size: 1.5rem;
      padding: 15px;
      width: 170px;
      margin-right: 35px;
      margin-top: 5px;
    }
  }
}

@media screen and (max-width: 1024px) {
  #main-site-form {
    background: none;
  }
  .form .container-fluid {
    padding-left: 3%;
    padding-right: 3%;
  }
  .form {
    margin-top: 0px;
    background-image: none;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .form input[type="submit"] {
    margin-right: 0;
    margin-top: 20px;
    margin-bottom: 15px;
    padding: 14px;
  }
}
