.main-references {
  margin-top: 40px;
  margin-bottom: 80px;
  //padding-bottom: 40px;
  min-height: 200px;
  display: flex;
  align-items: center;
  width: 100%;
  .swiper-wrapper {
  }
}

.main-references .swiper-button-next, .main-references .swiper-button-prev {
  height: 40px;
  width: 40px;
  background-size: cover;
}

.main-references .swiper-button-next {
  background-image: url(../images/arrow-right.svg);
  right: 20px;
}
.main-references .swiper-button-prev {
  background-image: url(../images/arrow-left.svg);
  left: 20px;
}
.reference-slide.swiper-slide-active {
  padding-left: 60px;
  transition: all 0.3s;
}
.reference-slide.swiper-slide-active + .reference-slide + .reference-slide {
  padding-right: 60px;
  transition: all 0.3s;
}
.reference-slide {
  display: flex;
  justify-content: center;
  //padding: 0 30px;
  transition: all 0.3s;
  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    padding-right: 20px;
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
  &__content {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    h3 {
      font-family: $Panton-Bold;
      font-size: 2.3rem;
      padding-bottom: 15px;
      text-transform: uppercase;
    }
    p {
      font-size: 1.6rem;
    }
    a {
      font-family: $Panton-Bold;
      font-size: 1.6rem;
      color: $black;
    }
  }
}

.main-references.swiper-container-horizontal>.swiper-scrollbar {
 left: 0%;
 width: 100%;
 height: 1px;
 background: rgba(0,0,0,1);
}

.main-references .swiper-scrollbar {
  border-radius: 0;
}
.main-references .swiper-scrollbar-drag {
  border-radius: 0;
  height: 8px;
  top: -4px;
  background-color: $yellow;
  //width: 50px !important;
}

@media screen and (max-width: 1500px) {
  .reference-slide.swiper-slide-active {
    padding-left: 55px;
    transition: all 0.3s;
  }
  .reference-slide.swiper-slide-active + .reference-slide + .reference-slide {
    padding-right: 55px;
    transition: all 0.3s;
  }
  .main-references .swiper-button-next, .main-references .swiper-button-prev {
    height: 35px;
    width: 35px;
    background-size: cover;
  }
  .main-references {
    margin-top: 25px;
    margin-bottom: 25px;
    padding-bottom: 25px;
    min-height: 170px;
  }
  .reference-slide {
    &__image {
      width: 100px;
      padding-right: 15px;
      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
    &__content {
      max-width: 250px;
      h3 {
        font-size: 1.7rem;
        padding-bottom: 10px;
      }
      p {
        font-size: 1.2rem;
      }
      a {
        font-size: 1.2rem;
      }
    }
  }

}

@media screen and (max-width: 1024px) {
  .main-references {
    padding-bottom: 50px;
  }
  .reference-slide.swiper-slide-active {
    padding-left: 0px;
    transition: all 0.3s;
  }
  .reference-slide.swiper-slide-active + .reference-slide + .reference-slide {
    padding-right: 0px;
    transition: all 0.3s;
  }
  .main-references .swiper-button-next, .main-references .swiper-button-prev {
    top: 88%;
  }
  .main-references .swiper-button-next {
    right: 44%;
  }
  .main-references .swiper-button-prev {
    left: 44%;
  }
}


@media screen and (max-width: 500px) {
  .main-references .swiper-button-next {
    right: 38%;
  }
  .main-references .swiper-button-prev {
    left: 38%;
  }
  .main-references .swiper-button-next, .main-references .swiper-button-prev {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 350px) {
  .main-references .swiper-button-next {
    right: 35%;
  }
  .main-references .swiper-button-prev {
    left: 35%;
  }
  .main-references .swiper-button-next, .main-references .swiper-button-prev {
    width: 30px;
    height: 30px;
  }
}
