.line {
  width: 100%;
  //display: flex;
  align-items: center;
  position: relative;
  display: block;
  float: left;
  h1,h2 {
    padding-right: 4%;
    padding-left: 4%;
    background-color: $white;
    z-index: 2;
    font-family: $Panton-LightCaps;
    font-size: 3.8rem;
    text-transform: uppercase;
    display: inline-block;
    span {
      font-family: $Panton-Bold;
    };
  }
  &:after {
    z-index: -1;
    display: block;
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: $black;
    position: absolute;
    top: 50%;
    //margin-right: -50%;
    transform: translate(0%, -50%);
  }
}

.reverse {
  justify-content: flex-end;
  h2,h1 {
    float: right;
  }
  h1 {
    padding-right: 0;
  }
  h2:after {
    content: "";
    display: block;
    clear: both;
  }
}

.clear {
  clear: both;
}
@media screen and (max-width: 1500px) {
  .line {
    h1,h2 {
      font-size: 2.6rem;
    }
  }
}

@media screen and (max-width: 500px) {
  .line:after {
    //display: none;
  }
}
