.about {
  margin-top: 10px;
}

.about__img1 {
  background-image: url(../images/about1.jpg);
}
.about__img2 {
  background-image: url(../images/about2.jpg);
}
.about__img3 {
  background-image: url(../images/about3.jpg);
}
// XXXXXXXXXXXXXXXXXXXXXXXX
.image {
  height: 600px;
  position: relative;
  cursor: pointer;
}

.image__front, .image__back {
  backface-visibility: hidden;
  transition: transform 0.4s;
}
.image__back {
  transform: rotateY(-180deg);
}
.image:hover .image__front {
  transform: rotateY(-180deg);
}
.image:hover .image__back {
  transform: rotateY(0);
}
.image__background {
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.about__img {
  height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  //background-size: 100%;
  transition: all 0.3s;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}
.about__img:hover {
  background-size: 110%;
  transition: all 0.3s;
}
.padding0 {
  padding-left: 0;
  padding-right: 0;
}
.about__background {
  background-size: cover;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
}
.about__img:hover .about__background {
  transform: scale(1.1);
  transition: all 0.3s;
}
// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

// XXXXXXXXXXXXXXXX
.about__content {
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px 3%;
  &--img-container {
    height: 70px;
    margin-bottom: 20px;
    display: flex;
    //align-items: flex-end;
    img {
      display: block;
    }
  }
  h2 {
    font-family: $Panton-Bold;
    font-size: 2.4rem;
    padding-bottom: 50px;
  }
  p {
    text-align: center;
    font-family: $Panton-LightItalic;
    font-size: 2.1rem;
    line-height: 3.1rem;
    padding-bottom: 20px;
    width: 100%;
  }
  p.about__number {
    font-family: $Panton-Italic;
    line-height: 2.1rem;
  }
}


///  sldier  ///////////////////////////////////////////
.about__slider {
  height: 750px;
}
.about__slider--slide {
  height: 600px;
  display: flex;
  &-img {
    height: 600px;
    width: 50%;
    margin-right: 10px;
    background-image: url(../images/about__slider-back.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  &-text {
    height: 600px;
    width: 50%;
    margin-left: 10px;
    background-color: #F4F4F4;
  }
}

.slide-text {
  padding: 30px 0;
  &__element {
    height: 50%;
    display: flex;
    overflow: hidden;
    &-img {
      width: 150px;
      display: flex;
      justify-content: center;
      figure {
        height: 100px;
        width: 100px;
        border-radius: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }
    &-text {
      width: 85%;
      padding-right: 50px;
      h2 {
        padding: 30px 0;
        font-family: $Panton-LightCaps;
        font-size: 3.6rem;
        text-transform: uppercase;
        span {
          font-family: $Panton-Bold;
        }
      }
      p {
        font-family: $Panton-Light;
        font-size: 1.6rem;
        line-height: 3rem;
      }
    }
  }
}

.slide-text__element-img1 {
  background-image: url(../images/about-slide-icon1.jpg);
}
.slide-text__element-img2 {
  background-image: url(../images/about-slide-icon2.jpg);
}

.about__slider.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 85px;
}
.about__slider.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 6px;
}
.about__slider .swiper-pagination-bullet {
  background: $white;
  height: 20px;
  width: 20px;
  opacity: 1;
  border: 1px solid $black;
}
.about__slider .swiper-pagination-bullet-active {
  background: $yellow;
  border: 1px solid $yellow;
}

@media screen and (max-width: 1500px) {
  .about__slider {
    height: 550px;
  }
  .about__slider--slide, .about__slider--slide-img, .about__slider--slide-text {
    height: 450px;
  }
  .about__slider.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 55px;
  }
  .about__slider .swiper-pagination-bullet {
    height: 15px;
    width: 15px;
  }
  .slide-text__element-img figure {
    height: 75px;
    width: 75px;
  }
  .slide-text__element-img {
    width: 115px;
  }
  .slide-text__element-text h2 {
    padding: 25px 0 15px 0;
    font-size: 2.5rem;
  }
  .slide-text {
    padding: 20px 0;
  }
  .slide-text__element-text p {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .about__img:hover .about__background {
    transform: scale(1);
    transition: all 0.3s;
  }
  .about__slider--slide {
    height: 600px;
    display: flex;
    flex-wrap: wrap;
    &-img {
      width: 100%;
      margin-right: 0px;
    }
    &-text {
      width: 100%;
      margin-left: 0px;
    }
  }
  .about__slider--slide-img {
    height: 350px;
  }
  .about__slider--slide-text, .about__slider--slide, .about__slider {
    height: auto;
  }
  .slide-text__element {
    height: auto;
  }
  .slide-text__element-text p {
    padding-bottom: 15px;
  }
  .about__slider.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 15px;
  }
  .slide-text {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 600px) {
  .slide-text__element-img {
    display: none;
  }
  .slide-text__element-text {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
  .slide-text {
    padding-top: 0;
  }
}
/// counter ////////////////////////////////////////////////////////

.about__counter {
  margin-top: 100px;
  margin-bottom: 100px;
  h2 {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 3.6rem;
    font-family: $Panton-Bold;
    padding-bottom: 60px;
  }
  h3 {
    font-size: 5.6rem;
    font-family: $Panton-Bold;
    padding: 30px 0;
    margin-bottom: 40px;
    position: relative;
    color: #575756;
    width: 100%;
    text-align: center;
    &:after {
      content: "";
      width: 100px;
      height: 4px;
      background-color: $yellow;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, 0)
    }
  }
  p {
    padding-top: 10px;
    font-size: 3.3rem;
    font-family: $Panton-LightItalic;
  }
}

.about__counter-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about__counter-box--image {
  height: 100px;
  display: flex;
  margin-bottom: 20px;
  img {
    max-height: 100%;
    width: auto;
  }
}

@media screen and (max-width: 1500px) {
  .about__counter {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .about__counter h2 {
    font-size: 2.5rem;
    padding-bottom: 40px;
  }
  .about__counter-box--image {
    height: 70px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    img.exp {
       max-height: 45px;
    }
  }
  .about__counter h3 {
    font-size: 4rem;
    padding: 18px 0;
    margin-bottom: 25px;
  }
  .about__counter h3:after {
    width: 70px;
    height: 3px;
  }
  .about__counter p {
    font-size: 2.3rem;
    padding-top: 5px;
    text-align: center;
  }
}

@media screen and (max-width: 1024px) {
  .about__counter h3 {
    font-size: 3rem;
  }
  .about__counter p {
    font-size: 2rem;
  }
  .about__counter-box--image {
    height: 60px;
  }
}

@media screen and (max-width: 760px) {
  .about__counter {
    margin-top: 0px;
    margin-bottom: 50px;
  }
  .about__counter-box {
    padding-bottom: 50px;
  }
  .about__counter-box:last-of-type {
    padding-bottom: 0px;
  }
  .about__counter-box--image {
    height: 50px;
    img.exp {
      max-height: 40px;
    }
  }
  .about__counter h3 {
    margin-bottom: 20px;
  }
}
///////////////////////////////////////////////////////////////

@media screen and (max-width: 1500px) {
  .about__img {
    height: 400px;
  }

  .about__content {
    height: 400px;
    padding: 30px 3%;
    &--img-container {
      height: 50px;
      margin-bottom: 15px;
    }
    img {
      transform: scale(0.8);
    }
    h2 {
      font-family: $Panton-Bold;
      font-size: 1.7rem;
      padding-bottom: 40px;
    }
    p {
      text-align: center;
      font-family: $Panton-LightItalic;
      font-size: 1.5rem;
      line-height: 2.2rem;
      padding-bottom: 20px;
    }
    p.about__number {
      font-family: $Panton-Italic;
      line-height: 1.6rem;
    }
  }
}

@media screen and (max-width: 500px) {
  .about__img {
    height: 300px;
  }

  .about__content {
    height: 300px;
  }
}
