.footer {
  width: 100%;
  color: $white;
  background-color: #3C3C3B;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  padding-right: 50px;
  img {
    height: 50px;
  }
  .creative {
    display: block;
    margin: 0 auto;
  }
  &__logo {
    width: 60%;
    display: flex;
    padding-left: 5%;
    align-items: center;
    font-size: 2rem;
  }
  &__contact, &__open {
    width: 20%;
    border-left: 1px solid $white;
    padding-left: 2%;
    padding-right: 10px;
    display: flex;
    h2 {
      font-size: 1.7rem;
      padding-right: 30px;
    }
    p {
      font-size: 1.3rem;
      padding-bottom: 5px;
    }
    a {
      display: block;
      color: $white;
      font-size: 1.3rem;
      padding-bottom: 5px;
    }
  }
}
.creative__tablet {
  display: none;
}
.footer .creative img {
  transform: scale(0.7) !important;
}
@media screen and (max-width: 1500px) {
  .footer {
    padding-top: 20px;
    padding-bottom: 20px;
    &__logo {
      width: 60%;
      padding-left: 5%;
      font-size: 2rem;
    }
    &__contact, &__open {
      width: 20%;
      padding-left: 2%;
      padding-right: 10px;
      h2 {
        font-size: 1.3rem;
        padding-right: 20px;
      }
      p {
        font-size: 1rem;
        padding-bottom: 5px;
      }
      a {
        font-size: 1rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    padding-top: 40px;
    padding-bottom: 40px;
    flex-wrap: wrap;
    &__logo {
      width: 100%;
      padding-left: 4%;
      font-size: 2rem;
      padding-bottom: 30px;
    }
    &__contact, &__open {
      border: none;
      width: 100%;
      padding-left: 4%;
      padding-right: 10px;
      padding-bottom: 30px;
      h2 {
        font-size: 1.3rem;
        width: 150px;
      }
      p {
        font-size: 1rem;
        padding-bottom: 5px;
      }
    }
    &__open {
      padding-bottom: 0;
    }
  }
  .footer .creative {
    display: none;
  }
  .creative__tablet {
    display: block;
    margin-top: 30px;
    padding-left: 4%;
  }
}
