.offert {
  width: 92%;
  margin: 0 4%;
  display: flex;
  padding: 50px 0;
  h2, h3 {
    font-family: $Panton-Bold;
    font-size: 2.4rem;
    padding-bottom: 50px;
    visibility: hidden;
  }
  &-contact {
    width: 27%;
    margin-right: 7%;
    &__box {
      margin-top: 40px;
      border: 1px solid $black;
      img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
      }
      &--data {
        background-color: $yellow;
        display: flex;
        flex-direction: column;
        padding: 40px 20px;
        padding-right: 10px;
        a {
          color: $white;
          font-size: 2.7rem;
          line-height: 4.6rem;
        }
      }
    }
  }
  &-article {
    width: 66%;
    p {
      font-family: $Panton-Light;
      font-size: 2rem;
      line-height: 3.8rem;
    }
    &__background {
      margin: 50px 0;
      height: 380px;
      background-image: url(../images/offert-background.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    &__content {
      display: flex;
      flex-wrap: wrap;
      &-list {
        width: 52.5%;
        //margin-right: 5%;
        ul {
          li.list__element {
            a.list__link {
              display: block;
              font-family: $Panton-SemiBold;
              color: #1D1D1B;
              font-size: 2.7rem;
              padding: 20px 0;
              padding-left: 75px;
              background-image: url(../images/offert-list.svg);
              background-repeat: no-repeat;
              background-position: left center;
            }
            a.offert-active {
              background-image: url(../images/offert-list-active.svg);
              background-position: left center;
            }
            .list__producent {
              padding-left: 75px;
              padding-bottom: 20px;
              &--description {
                font-size: 1.8rem;
                line-height: 3rem;
              }
              &--name {
                font-family: $Panton-Bold;
                color: $black;
                font-size: 2rem;
                transition: color 0.3s;
                &:hover {
                  color: $yellow;
                  transition: color 0.3s;
                }
              }
            }
            .list__producent:last-child {
              padding-bottom: 0;
            }
          }
        }
      }
      &-list:last-child {
        margin-right: 0%;
        width: 47.5%;
      }
      &-text {
        width: 40%;
      }
    }
  }
}

.list-second {
  margin-top: 59px;
}
.offert-article__content .line {
  margin-bottom: 20px;
}
.offert-article__content .line h2 {
  visibility: visible;
  font-size: 2.4rem;
  font-family: "Panton-Bold", sans-serif;
  padding-bottom: 0;
  padding-left: 0;
}

.offert-article__content-list .list {
  padding-top: 25px;
}
.offert__recommended {
  width: 100%;
  display: block;
  background-color: $yellow;
  margin-top: 20px;
  font-size: 2.7rem;
  color: $white;
  text-align: center;
  padding: 15px;
  border: 1px solid $black;
  transition: all 0.3s;
  &:hover {
    background-color: $white;
    color: $black;
    transition: all 0.3s;
  }
}

.offert-contact h2, .offert-contact h3, .offert-article__heading, .offert-contact__box, .offert-article__background {
  visibility: hidden;
}
.offert-article__content-list h2 {
  visibility: visible;
}
.offert-contact h2:first-child {
  padding-bottom: 10px;
}
.offert-contact h3:nth-child(2) {
  padding-bottom: 95px;
}


@media screen and (max-width: 1500px) {
  .offert-contact h2:first-child {
    padding-bottom: 0px;
  }
  .offert-contact h3:nth-child(2) {
    padding-bottom: 65px;
  }
  .offert-article__content .line {
    margin-bottom: 15px;
  }
  .offert-article__content .line h2 {
    font-size: 1.8rem;
  }
  .list-second {
    margin-top: 57px;
  }
  .offert__recommended {
    margin-top: 15px;
    font-size: 1.8rem;
    padding: 13px;
  }
  .offert h2, .offert h3 {
    font-size: 1.7rem;
    line-height: 2.7rem;
    padding-bottom: 30px;
  }
  .offert-article p {
    font-size: 1.4rem;
    line-height: 2.7rem;
  }
  .offert-contact__box--data {
    padding: 25px 15px;
  }
  .offert-contact__box--data a {
    font-size: 1.8rem;
    line-height: 3.2rem;
  }
  .offert-article__background {
    height: 270px;
    margin: 35px 0;
  }
  .offert-contact__box {
    margin-top: 15px;
  }

  .offert-article__content-list ul li.list__element a.list__link {
    padding: 17px 0;
    padding-left: 55px;
    background-size: 35px;
    font-size: 1.9rem;
  }
  .offert-article__content-list ul li.list__element .list__producent {
    padding-left: 55px;
  }
  .offert-article__content-list ul li.list__element .list__producent--description {
    font-size: 1.3rem;
    line-height: 2.2rem;
  }
  .offert-article__content-list ul li.list__element .list__producent--name {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .offert-contact h3:nth-child(2) {
    padding-bottom: 20px;
  }
  .offert-contact h2, .offert-contact h3, .offert-article__heading, .offert-contact__box, .offert-article__background {
    visibility: visible;
  }
  .offert {
    flex-wrap: wrap;
  }
  .offert-contact {
    width: 100%;
    margin-right: 0;
  }
  .offert-article {
    width: 100%;
    padding-top: 30px;
  }
  .offert-contact__box img {
    display: none;
  }
  .offert h2, .offert h3 {
    padding-bottom: 15px;
  }
  .offert-contact__box {
    margin-top: 5px;
  }
  .offert-contact__box--data {
    padding: 20px;
  }
  .list-second {
    margin-top: 42px;
  }
/*  .offert-article__content-list {
    width: 47.5%;
    margin-right: 2.5%;
  }
  .offert-article__content-text {
    width: 47.5%;
    margin-left: 2.5%;
  }*/
}

@media screen and (max-width: 600px) {
  .offert-article__content-list .list {
    padding-top: 0px;
  }
  .offert-article__content {
    flex-wrap: wrap;
  }
  .offert-article__content-list {
    width: 100%;
    margin-right: 0;
    order: 2;
    padding-top: 30px;
  }
  .offert-article__content-text {
    width: 100%;
    margin-left: 0;
    order: 1;
  }
  .offert-contact__box {
    margin-left: -4.5%;
    margin-right: -4.5%;
    border: none;
  }
  .offert {
    padding: 30px 0;
  }
  .offert-article__background {
    height: 170px;
  }
  .offert-article__content-list ul li.list__element .list__producent {
    padding-left: 0;
  }
  .line.list-second {
    display: none;
  }
  .list.list-second {
    margin-top: 0;
    padding-top: 0;
  }
  .offert-article__content-list:last-child {
    padding-top: 0;
    width: 100%;
  }
  .offert-article__content-list {
    padding-top: 20px;
  }
}

@media screen and (max-width: 359px) {
  .offert-contact__box--data a {
    font-size: 1.7rem;
  }
}
