.article {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__heading {
    font-family: $Panton-Bold;
    font-size: 3.2rem;
    color: $yellow;
    padding: 30px 20px;
    text-transform: uppercase;
    text-align: center;
  }
  &__data {
    font-family: $Panton-Italic;
    font-size: 2.5rem;
    color: #808080;
    text-align: center;
  }
  &__text {
    font-size: 2rem;
    text-align: center;
    padding: 45px 10%;
    line-height: 4rem;
    width: 100%;
  }
  &__link {
    display: block;
    background-color: $yellow;
    color: $white;
    font-family: $Lato-Bold;
    font-size: 2rem;
    width: 250px;
    height: 70px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    transition: all 0.3s;
  }
  &__link:hover {
    background-color: $black;
    color: $yellow;
    transition: all 0.3s;
  }
  &__background {
    width: 100%;
    height: 650px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.article__background1 {
  background-image: url(../images/news1.jpg);
}
.article__background2 {
  background-image: url(../images/news2.jpg);
}
.article__background3 {
  background-image: url(../images/news3.jpg);
}

.article-site-control {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  margin: 40px 0;
  > a {
    color: #808080;
    font-size: 2.5rem;
    font-family: $Panton-Italic;
    transition: all 0.3s;
  }
  > a:hover {
    color: $black;
    transition: all 0.3s;
  }
  &__numbers {
    height: 100%;
    border-left: 1px solid $black;
    border-right: 1px solid $black;
    padding-left: 30px;
    padding-right: 30px;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      color: #3C3C3B;
      font-size: 3rem;
      padding: 10px;
      transition: all 0.3s;
    }
    a:hover {
      color: #808080;
      transition: all 0.3s;
    }
    a.article-active {
      color: $yellow;
    }
  }
}

@media screen and (max-width: 1500px) {
  .article__heading {
    font-size: 2.3rem;
    padding: 25px 20px;
  }
  .article__data {
    font-size: 1.75rem;
  }
  .article__text {
    font-size: 1.4rem;
    line-height: 3rem;
    padding: 30px 10%;
  }
  .article__link {
    font-size: 1.4rem;
    width: 170px;
    height: 50px;
    margin-bottom: 30px;
  }
  .article__background {
    height: 450px;
  }
  .article-site-control > a {
    font-size: 1.7rem;
  }
  .article-site-control__numbers a {
    font-size: 2.1rem;
    padding: 8px;
  }
  .article-site-control__numbers {
    padding-left: 20px;
    padding-right: 20px;
  }
  .article-site-control {
    height: 50px;
    margin: 30px 0;
  }
}

@media screen and (max-width: 1024px) {
  .article__text {
    padding: 30px 5%;
  }
  .article__background {
    height: 300px;
  }
}

@media screen and (max-width: 500px) {
  .article__data  {
    font-size: 1.5rem;
  }
  .article__heading {
    padding: 25px 15px;
    padding-bottom: 15px;
  }
  .article__text {
    line-height: 2.5rem;
  }
  .article__background {
    height: 200px;
  }
  .article__text {
    padding: 25px 5%;
  }
  .article__link {
    width: 150px;
    height: 45px;
    margin-bottom: 25px;
  }
  .article-site-control > a {
    padding: 20px 0;
    font-size: 1.3rem;
  }
  .article-site-control {
    height: 45px;
  }
}

@media screen and (max-width: 359px) {
  .article-site-control__numbers {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 10px;
  }
}
