.line-padding {
  padding: 40px 0;
  overflow: hidden;
}
.contact-wrapper {
  padding-left: 4%;
  padding-right: 4%;
  overflow: hidden;
}
.contact__adress {
  padding-top: 30px;
  padding-bottom: 30px;
}
.contact__adress-element {
  padding: 0;
  h2 {
    font-family: $Panton-Bold;
    font-size: 2.8rem;
    min-height: 80px;
    text-transform: uppercase;
  }
  p, a {
    font-size: 2.4rem;
    line-height: 4rem;
    color: #3C3C3B;
    display: block;
  }
}


.contact__adress-element:nth-child(4), .contact__adress-element:nth-child(5), .contact__adress-element:nth-child(6) {
  padding-top: 50px;
}

.form.contact-form {
  background-position: left center;
  padding-top: 0;
}
.form.contact-form .container-fluid {
  padding-left: 14%;
  padding-right: 0;
}

.form.contact-form input[type="submit"] {
  margin-right: 0;
  padding: 15px;
}

/*.line.line-contact:before  {
  position: absolute;
  right: 0;
  content: "";
  width: 4%;
  height: 10px;
  background-color: #fff;
  z-index: 2;
}

.line.line-contact.reverse:before  {
  position: absolute;
  left: 0;
  content: "";
  width: 4%;
  height: 10px;
  background-color: #fff;
  z-index: 2;
}*/

.line.line-contact:after {
  width: 100%;
}

.line.line-contact h2 {
  padding-left: 0;
}

.line.line-contact.reverse h2 {
  padding-right: 0;
  padding-left: 4%;
}

@media screen and (max-width: 1500px) {
  .contact__adress-element h2 {
    font-size: 2rem;
    min-height: 60px;
  }
  .contact__adress-element p, .contact__adress-element a {
    font-size: 1.7rem;
    line-height: 3rem;
  }
  .line-padding {
    padding: 30px 0;
    overflow: hidden;
  }
  .contact__adress {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .form.contact-form .container-fluid {
    padding-left: 19%;
  }
}

@media screen and (max-width: 1024px) {
  .form.contact-form .container-fluid {
    padding-left: 0;
  }
  .contact__adress .row {
    display: flex;
    flex-wrap: wrap;
  }
  .contact__adress-element:nth-child(3), .contact__adress-element:nth-child(4), .contact__adress-element:nth-child(5), .contact__adress-element:nth-child(6) {
    padding-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  .contact__adress-element {
    width: 100%;
    padding-top: 30px;
  }
  .contact__adress-element:nth-child(1) {
    padding-top: 0;
  }
  .line-padding {
    padding: 20px 0;
  }
  .contact__adress-element h2 {
    min-height: auto;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 500px) {
  .contact__adress-element h2 {
    font-size: 1.8rem;
  }
  .contact__adress-element p, .contact__adress-element a {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
  .contact__adress {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 350px) {
  .contact__adress-element p, .contact__adress-element a {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}
