.arrow {
  width: 60px;
  height: 60px;
  bottom:40px;
  right: 40px;
  border-radius: 100%;
  position: fixed;
  background-color: $yellow;
  z-index: 999;
  background-image: url(../images/arrow-up.svg);
  background-size: contain;
  background-repeat: no-repeat;
  display: none;
  opacity: 0.7;
  transition: all 0.3s;
  &:hover {
    opacity: 1;
    transition: all 0.3s;
  }
}

@media screen and (max-width: 1500px) {
  .arrow {
    width: 40px;
    height: 40px;
    bottom: 30px;
    right: 30px;
  }
}

@media screen and (max-widtH: 1300px) {
  .arrow {
    right: 20px;
  }
}

@media screen and (max-width: 500px) {
  .arrow {
    bottom: 15px;
    right: 10px;
  }
}
