.row-custom-margin {
  margin-left: -22px;
  margin-right: -22px;
}
.news-container {
  margin-top: 40px;
  margin-bottom: 40px;
}
.news:hover {
  background-size: 110%;
  transition: all 0.3s;
}
.news {
  height: 370px;
  display: flex;
  align-items: flex-end;
  border: 7px solid $white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-left: 0;
  padding-right: 0;
  transition: all 0.3s;
  background-size: 100%;
  visibility: hidden;
}
.news1 {
  background-image: url(../images/main-news1.jpg);
}
.news2 {
  background-image: url(../images/main-news2.jpg);
}
.news3 {
  background-image: url(../images/main-news3.jpg);
}
.news4 {
  background-image: url(../images/main-news4.jpg);
  height: 740px;
  .news__overlay {
    height: 24.5%;
    text-transform: uppercase;
    h2,h3 {
      font-size: 3.2rem;
      width: 100%;
    }
    p {
      width: 100%;
    }
  }
  a {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: $yellow;
    border-radius: 50px;
    font-size: 3.1rem;
    font-family: $Lato-Bold;
    text-transform: uppercase;
    padding: 15px 20px;
    color: $white;
    transition: all 0.3s;
  }
  a:hover {
    color: $yellow;
    background-color: $black;
    transition: all 0.3s;
  }
}

.news__overlay {
  height: 50%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.66);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.2rem;
  color: $white;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  transition: all 0.3s;
  h2 {
    font-family: $Panton-Bold;
    transition: all 0.3s;
    width: 100%;
  }
  p {
    width: 100%;
  }
}

.news:hover .news__overlay {
  transition: all 0.3s;
  background-color: rgba(0, 0, 0, 0.86);
}

@media screen and (max-width: 1500px) {
  .news-container {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .news {
    height: 270px;
    border: 5px solid $white;
  }
  .news__overlay {
    font-size: 16px;
  }
  .news4 {
    height: 540px;
    .news__overlay {
      h2,h3 {
        font-size: 2.2rem;
      }
    }
  }
  .news4 a {
    font-size: 2rem;
    padding: 10px 15px;
  }
}

@media screen and (max-width: 1024px) {
  .news {
    background-size: cover;
    visibility: visible;
  }
  .news4 {
    height: 270px;
    .news__overlay {
      height: 50%;
    }
  }
}

@media screen and (max-width: 500px) {
  .news-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .news4 .news__overlay h2, .news4 .news__overlay h3 {
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 360px) {
  .news4 {
    .news__overlay {
      padding-top: 20px;
      height: 50%;
    }
  }
}
