.gallery {
  width: 92%;
  margin: 0 4%;
  &__categories {
    padding: 70px 0;
    padding-bottom: 95px;
    ul {
      width: 100%;
      display: flex;
      position: relative;
      li {
          position: relative;
          padding-left: 30px;
          padding-right: 60px;
        a {
          text-decoration: none;
          font-size: 2.8rem;
          color: #3c3c3b;
          position: relative;
          transition: color 0.3s;
        }
        a.gallery-active {
          color: $yellow;
          &:before {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 30px 50px 0 50px;
            border-color: $yellow transparent transparent transparent;
            position: absolute;
            top: 65px;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, 0);
          }
          &:after {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 30px 50px 0 50px;
            border-color: $white transparent transparent transparent;
            position: absolute;
            top: 60px;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, 0);
            z-index: 2;
          }
        }
        a:hover {
          color: $yellow;
          transition: color 0.3s;
        }
      }
      &:after {
        content: "";
        width: 100%;
        height: 4px;
        background-color: $yellow;
        position: absolute;
        bottom: -35px;
      }
    }
  }
  &__content {
    width: 102%;
    margin-left: -1%;
    margin-right: -1%;
    flex-wrap: wrap;
    margin-bottom: 50px;
    float: left;
    &:after {
      content: "";
      clear: both;
    }
    &--element {
      float: left;
      margin: 1%;
      height: 380px;
      width: 31.3333%;
      background-image: url(../images/gallery-image.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      outline: none;
      &:after {
        content: "";
        clear: both;
      }
      .overlay {
        height: 100%;
        width: 100%;
        border: 25px solid $yellow;
        background: rgba(0,0,0,0.7);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: all 0.3s;
        img {
          margin-bottom: 30px;
          transition: all 0.3s;
          max-width: 100%;
        }
        h2 {
          font-family: $Panton-Bold;
          font-size: 2.8rem;
          color: $white;
          transition: all 0.3s;
          padding: 0 40px;
          text-align: center;
        }
      }
    }
    &--element:hover .overlay {
      opacity: 1;
      transition: all 0.3s;
    }
  }
}

@media screen and (max-width: 1500px) {
  .gallery__categories ul li  {
    padding-right: 40px;
  }
  .gallery__categories {
    padding: 50px 0;
    padding-bottom: 60px;
  }
  .gallery__categories ul li a {
    font-size: 2rem;
  }
  .gallery__categories ul:after {
    height: 3px;
    bottom: -20px;
  }
  .gallery__categories ul li a.gallery-active:before {
    top: 39px;
    border-width: 25px 40px 0 40px;
  }
  .gallery__categories ul li a.gallery-active:after {
    top: 35px;
    border-width: 25px 40px 0 40px;
  }
  .gallery__content--element {
    height: 270px;
  }
  .gallery__content--element .overlay h2 {
    font-size: 2rem;
  }
  .gallery__content--element .overlay img {
    transform: scale(0.7);
    margin-bottom: 20px;
  }
  .gallery__content--element .overlay {
    border-width: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .gallery__content--element {
    width: 48%;
    height: 300px;
  }
  .gallery__categories ul {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .gallery__categories ul li {
    padding-right: 30px;
  }
}

@media screen and (max-width: 800px) {
  .gallery__content--element {
    width: 48%;
    height: 230px;
  }
}

@media screen and (max-width: 500px) {
  .gallery__categories ul {
    flex-direction: column;
    text-align: center;
  }
  .gallery__categories ul li a.gallery-active:after,.gallery__categories ul li a.gallery-active:before {
    display: none;
  }
  .gallery__categories ul li {
    padding: 10px;
  }
  .gallery__categories {
    padding-top: 30px;
    padding-bottom: 50px;
  }
  .gallery__content--element {
    width: 98%;
  }
  .gallery__content {
    margin-bottom: 30px;
  }
}
