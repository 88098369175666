.main-slider.swiper-container {
  width: 100%;
  height: 800px;
}
.main-slider-wrapper {
  position: relative;
}
.main-slider .swiper-slide1 {
  background-image: url(../images/main-slide.jpg);
}
.main-slider .swiper-slide2 {
  background-image: url(../images/banner-about.jpg);
}
.main-slider .swiper-slide3 {
  background-image: url(../images/main-slide.jpg);
}
.main-slider .swiper-slide4 {
  background-image: url(../images/banner-about.jpg);
}
.main-slider .swiper-slide {
  color: $white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h2 {
    font-size: 3.6rem;
    padding-bottom: 45px;
    text-transform: uppercase;
    z-index: 2;
    padding-right: 15%;
    padding-left: 15%;
    text-align: center;
  }
  p {
    font-size: 3.6rem;
    font-family: $Panton-LightItalic;
    padding-bottom: 100px;
    z-index: 2;
    padding-right: 15%;
    padding-left: 15%;
    text-align: center;
  }
  a {
    color: $white;
    font-size: 2.5rem;
    background-color: $yellow;
    border-radius: 25px;
    padding: 10px 15px;
    z-index: 11;
    transition: all 0.3s;
  }
  a:hover {
    background-color: $black;
    color: $yellow;
    transition: all 0.3s;
  }
}

.main-slider .swiper-slide .overlay {
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0.7) 0%,rgba(255,255,255,0) 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

//// pagination styles
.main-slider.swiper-container-vertical>.swiper-pagination-bullets {
 left: 50px;
 right: auto;
}
.main-slider .swiper-pagination-bullet {
  opacity: 1;
}
.main-slider.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
  background-image: url(../images/main-slider-pagination.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.main-slider.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet-active {
  background-image: url(../images/main-slider-pagination.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.main-slider .swiper-pagination-bullet {
  width: 60px;
  height: 60px;
  background: transparent;
}
/////////////////////////
.main-button {
  width: 100%;
  position: relative;
  margin-top: -35px;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  &__circle {
    height: 70px;
    width: 1px;
    border-radius: 100%;
    //background-color: $yellow;
    //background-image: url(../images/main-button.svg);
    background-repeat: no-repeat;
    background-position: center;
    z-index: 4;
  }
}

.main-slider.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  position: relative;
}
.main-slider.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  position: relative;
}
#myCanvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

@media screen and (max-width: 1500px) {
  .main-button {
    margin-top: -25px;
    padding-bottom: 5px;
  }
  .main-button__circle {
    width: 50px;
    height: 50px;
    background-size: 20px;
  }
  .main-slider.swiper-container {
    width: 100%;
    height: 550px;
  }
  .main-slider .swiper-slide h2 {
    font-size: 2.6rem;
    padding-bottom: 30px;
  }
  .main-slider .swiper-slide p {
    font-size: 2.6rem;
    padding-bottom: 70px;
  }
  .main-slider .swiper-slide a {
    font-size: 1.8rem;
  }
  .main-slider.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    background-size: 20px;
  }
  .main-slider.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet-active {
    background-size: 20px;
  }
  .main-slider .swiper-pagination-bullet {
    width: 45px;
    height: 45px;
  }
}

@media screen and (max-width: 1024px) {
  .main-button {
    margin-top: -15px;
  }
  .main-button__circle {
    width: 40px;
    height: 40px;
    background-size: 18px;
  }
  .main-slider.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    background-image: url(../images/main-slider-pagination.svg);
    background-repeat: no-repeat;
    background-position: center;
  }

  .main-slider.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet-active {
    background-image: url(../images/main-slider-pagination.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .main-slider.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    background-size: 15px;
  }
  .main-slider.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet-active {
    background-size: 15px;
  }
  .main-slider .swiper-pagination-bullet {
    width: 35px;
    height: 35px;
  }
  .main-slider.swiper-container {
    width: 100%;
    height: 450px;
    margin-top: 60px;
  }
  .main-slider .swiper-slide h2 {
    font-size: 2.3rem;
    padding-bottom: 15px;
    padding-left: 5%;
    padding-right: 5%
  }
  .main-slider .swiper-slide p {
    font-size: 2.3rem;
    padding-bottom: 50px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .main-slider .swiper-slide a {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 800px) {
  .main-slider.swiper-container {
    width: 100%;
    height: 400px;
    margin-top: 60px;
  }
}

@media screen and (max-width: 500px) {
.main-button {
  padding-bottom: 10px;
  }
}
